import React, { useState, useEffect, useCallback } from 'react';
import TikTokComparison from '../TiktokComparaison/TiktokComparaison';
import { projectService } from '../../services/project.service.js';
import SubscriptionService from '../../services/subscription.service';
import FingerprintService from '../../services/fingerprint.service.js'; // Ajout de l'import
import './Hero.css';
import NewProjectModalV2 from '../Dashboard/NewProjectModalV2.jsx';

export default function Hero({ userCoins, onCoinsUpdate }) { // Ajouter onCoinsUpdate en prop
    const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userPlan, setUserPlan] = useState('free'); // Plan par défaut

    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                setIsLoading(true);
                const languagesData = await projectService.getLanguages();
                setLanguages(languagesData);
            } catch (error) {
                console.error('Erreur lors du chargement des langages:', error);
                setError('Une erreur est survenue lors du chargement des langues disponibles');
            } finally {
                setIsLoading(false);
            }
        };

        fetchLanguages();
    }, []);

    // Récupérer le plan d'abonnement de l'utilisateur
    useEffect(() => {
        const fetchUserSubscription = async () => {
            try {
                // Vérifier si l'utilisateur est connecté
                const token = localStorage.getItem('token');
                if (!token) {
                    console.log('Utilisateur non connecté, utilisation du plan gratuit par défaut');
                    return;
                }

                const subscription = await SubscriptionService.getCurrentSubscription();
                if (subscription && subscription.planId) {
                    setUserPlan(subscription.planId);
                    console.log('Plan d\'abonnement récupéré:', subscription.planId);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération du plan d\'abonnement:', error);
                // En cas d'erreur, on garde le plan gratuit par défaut
            }
        };

        fetchUserSubscription();
    }, []);

    // Gestionnaire pour la création de projet
    const handleProjectCreated = useCallback(() => {
        // Handle successful project creation - fermeture du modal maintenue
        console.log("Hero: Projet créé avec succès");
        setIsProjectModalOpen(false);
    }, []);

    // Nouveau gestionnaire pour la mise à jour des tokens
    const handleCoinsUpdated = useCallback((newCoins) => {
        console.log("Hero: Mise à jour des tokens:", newCoins);
        // Si une fonction onCoinsUpdate a été passée en prop, l'utiliser
        if (typeof onCoinsUpdate === 'function') {
            onCoinsUpdate(newCoins);
        }
    }, [onCoinsUpdate]);

    return (
        <section className="hero">
            <div className="hero-content">
                <div className="hero-text">
                    <h1 className="hero-title">
                        L'IA qui donne une nouvelle voix à vos contenus
                    </h1>
                    <p className="hero-description">
                        Transformez votre contenu avec notre technologie de doublage IA
                        de nouvelle génération. Une qualité studio en quelques clics.
                    </p>
                    <button
                        className="hero-cta"
                        onClick={() => setIsProjectModalOpen(true)}
                        disabled={isLoading || error}
                    >
                        <i className="fas fa-calendar-check"></i>
                        {isLoading ? 'Chargement...' : 'Traduis tes vidéos maintenant'}
                    </button>
                    {error && <p className="error-message">{error}</p>}
                </div>

                <div className="hero-preview">
                    <TikTokComparison videoUrl="https://images.unsplash.com/photo-1522529599102-193c0d76b5b6" />
                </div>
            </div>

            <NewProjectModalV2
                isOpen={isProjectModalOpen}
                onClose={() => setIsProjectModalOpen(false)}
                onProjectCreated={handleProjectCreated}
                onCoinsUpdated={handleCoinsUpdated} // Nouvelle prop
                allLanguages={languages}
                userCoins={userCoins}
                userPlan={userPlan}
            />
        </section>
    );
}