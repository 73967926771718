import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { authService } from '../../services/auth.service';

const Navbar = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        // Vérifier si l'utilisateur est connecté lors du chargement du composant
        const checkAuthStatus = () => {
            const isLoggedIn = authService.isAuthenticated();
            setIsAuthenticated(isLoggedIn);
        };

        checkAuthStatus();

        // Écouter les changements d'état d'authentification (facultatif)
        window.addEventListener('storage', (e) => {
            if (e.key === 'token') {
                checkAuthStatus();
            }
        });

        return () => {
            window.removeEventListener('storage', checkAuthStatus);
        };
    }, []);

    return (
        <nav className="navbar">
            <Link to="/" className="logo">Sumfy.</Link>
            <div className="nav-links">
                <Link to="/features">Fonctionnalités</Link>
                {<Link to="/pricing">Tarifs</Link>}

                {isAuthenticated ? (
                    <Link to="/dashboard" className="login-btn">Tableau de bord</Link>
                ) : (
                    <Link to="/login" className="login-btn">Se connecter</Link>
                )}
            </div>
        </nav>
    );
};

export default Navbar;