import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import AudioPlayer from '../../components/EditingSoftware/AudioPlayer'
import TranscriptViewer from '../../components/EditingSoftware/TranscriptViewer';
import Timeline from '../../components/EditingSoftware/Timeline';
import Header from '../../components/EditingSoftware/Header';
import VideoPlayer from '../../components/EditingSoftware/VideoPlayer';
import FlexibleLayout from '../../components/EditingSoftware/ModularLayout';
import { Box } from '@mui/material';
import { API_CONFIG } from '../../config/constants';
import ReferenceFilesManager from '../../components/EditingSoftware/ReferenceFilesManager';
import AudioVolumePanel from '../../components/EditingSoftware/AudioVolumePanel';
import LoadingScreen from '../../components/Loading/LoadingScreen';
function MainPage() {
    const [transcript, setTranscript] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [mutedTracks, setMutedTracks] = useState({});
    const [selectedBlock, setSelectedBlock] = useState(null);
    const [audioSegments, setAudioSegments] = useState({});
    const [message, setMessage] = useState('');
    const [videoUrl, setVideoUrl] = useState(null);
    const [isExporting, setIsExporting] = useState(false);

    const audioRefs = useRef({});
    const videoRef = useRef(null);
    const startTimeRef = useRef(0);
    const intervalRef = useRef(null);
    const [isVideoAudioEnabled, setIsVideoAudioEnabled] = useState(false);

    const [referenceFiles, setReferenceFiles] = useState([]);
    const [assetsLoaded, setAssetsLoaded] = useState(false);


    // Référence pour les AudioPlayers avec une structure plus claire
    const audioPlayerRefs = useRef({});

    const { projectId } = useParams();

    const fetchReferenceFiles = useCallback(async () => {
        try {
            const response = await fetch(
                `${API_CONFIG.BASE_URL}/projects/${projectId}/reference_files`,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            if (response.ok) {
                const data = await response.json();
                setReferenceFiles(data.reference_files);
            }
        } catch (error) {
            console.error('Error fetching reference files:', error);
        }
    }, [projectId]);

    const fetchTranscript = useCallback(async () => {
        try {
            const response = await axios.get(`${API_CONFIG.BASE_URL}/get_file/${projectId}/merged_transcript_with_audio.json`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                params: { timestamp: new Date().getTime() }
            });

            // Extraire les segments du transcript
            const data = response.data.segments || response.data;

            // Ajout des trim_start/trim_end si nécessaire
            const processedData = data.map(item => {
                if (!item.trim_start) {
                    return {
                        ...item,
                        trim_start: item.start,
                        trim_end: item.end,
                    };
                }
                return item;
            });

            // Mise à jour du transcript
            setTranscript(processedData);

            // Calculer la durée totale
            const totalDuration = Math.max(...processedData.map(item => item.end));
            setDuration(totalDuration);

            // Récupérer tous les speakers (SPEAKER_X et BACKGROUND_X)
            const uniqueSpeakers = [...new Set(processedData.map(item => item.speaker))];

            // Initialiser les états de mute pour tous les speakers
            const muted = Object.fromEntries(uniqueSpeakers.map(speaker => [speaker, false]));
            setMutedTracks(muted);

            // Grouper les segments par speaker
            const segments = Object.fromEntries(uniqueSpeakers.map(speaker => [
                speaker,
                processedData.filter(item => item.speaker === speaker)
            ]));
            setAudioSegments(segments);

        } catch (error) {
            setMessage('Failed to fetch transcript: ' + (error.response?.data?.msg || error.message));
        }
    }, [projectId]);

    const fetchVideoUrl = useCallback(async () => {
        try {
            const response = await axios.get(`${API_CONFIG.BASE_URL}/get_file/${projectId}/video.mp4`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });

            setVideoUrl(response.data?.url || null);
        } catch (error) {
            if (error.response?.status !== 404) {
                console.error('Failed to fetch video URL:', error);
                setMessage('Failed to fetch video: ' + (error.response?.data?.msg || error.message));
            }
        }
    }, [projectId]);

    useEffect(() => {
        fetchTranscript();
        fetchVideoUrl();
        fetchReferenceFiles();  // Ajouté ici
    }, [fetchTranscript, fetchVideoUrl, fetchReferenceFiles]);


    const checkAllAssetsLoaded = useCallback(() => {
        if (!transcript.length) return false;

        // Vérifier si tous les AudioPlayers ont terminé leur préchargement
        const allPlayersReady = Object.keys(audioPlayerRefs.current).every(speaker => {
            return audioPlayerRefs.current[speaker]?.isCacheReady?.();
        });

        return allPlayersReady;
    }, [transcript]);

    useEffect(() => {
        // Si on a déjà confirmé que tout est chargé, ne pas revérifier
        if (assetsLoaded) return;

        // Vérifier initialement si tout est chargé
        const isReady = checkAllAssetsLoaded();

        if (isReady) {
            setAssetsLoaded(true);
            return;
        }

        // Sinon, configurer un intervalle pour vérifier périodiquement
        const checkInterval = setInterval(() => {
            const isReady = checkAllAssetsLoaded();
            if (isReady) {
                setAssetsLoaded(true);
                clearInterval(checkInterval);
            }
        }, 500); // Vérifier toutes les 500ms

        return () => clearInterval(checkInterval);
    }, [assetsLoaded, checkAllAssetsLoaded, transcript]);


    const updateTime = useCallback(() => {
        if (isPlaying) {
            const elapsed = (Date.now() - startTimeRef.current) / 1000;
            setCurrentTime(prevTime => {
                const newTime = prevTime + elapsed;
                return newTime > duration ? 0 : newTime;
            });
            startTimeRef.current = Date.now();
        }
    }, [isPlaying, duration]);

    useEffect(() => {
        if (isPlaying) {
            startTimeRef.current = Date.now();
            intervalRef.current = setInterval(updateTime, 100);
            videoRef.current?.play();
        } else {
            clearInterval(intervalRef.current);
            videoRef.current?.pause();
        }

        return () => clearInterval(intervalRef.current);
    }, [isPlaying, updateTime]);

    const handlePlayPause = useCallback(() => {
        setIsPlaying(prev => !prev);
    }, []);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.code === 'Space' && !['INPUT', 'TEXTAREA'].includes(event.target.tagName)) {
                event.preventDefault();
                handlePlayPause();
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        return () => document.removeEventListener('keydown', handleKeyPress);
    }, [handlePlayPause]);

    const handleSeek = useCallback((newTime) => {

        const wasPlaying = isPlaying;
        if (wasPlaying) {
            setIsPlaying(false); // Mettre en pause pendant le seek
        }

        setCurrentTime(newTime);
        if (videoRef.current) {
            videoRef.current.currentTime = newTime;
        }
        startTimeRef.current = Date.now();

        const currentSegment = transcript.find(t => newTime >= t.start && newTime < t.end);

        Object.entries(audioRefs.current).forEach(([speaker, audio]) => {
            if (audio) {
                audio.currentTime = newTime;
                const isCurrentSpeaker = currentSegment && currentSegment.speaker === speaker;
                if (isPlaying && isCurrentSpeaker) {
                    audio.play().catch(e => console.error("Error playing audio:", e));
                } else {
                    audio.pause();
                }
            }
        });

        // Reprendre la lecture après un court délai
        if (wasPlaying) {
            setTimeout(() => {
                setIsPlaying(true);
            }, 100);
        }
    }, [transcript, isPlaying]);

    const handleSkip = useCallback((seconds) => {
        const newTime = Math.min(Math.max(currentTime + seconds, 0), duration);
        handleSeek(newTime);
    }, [currentTime, duration, handleSeek]);

    const toggleMute = useCallback((speaker) => {
        setMutedTracks(prev => ({ ...prev, [speaker]: !prev[speaker] }));
    }, []);

    const handleBlockSelect = useCallback((block) => {
        setSelectedBlock(block);
        if (block) {
            handleSeek(block.start);
        }
    }, [handleSeek]);


    // Gestionnaire pour recharger la waveform
    const handleWaveformReload = useCallback((speaker, updatedSegment) => {
        console.log("Reloading waveform for:", updatedSegment);
        // Mettre à jour le transcript directement
        setTranscript(prev => prev.map(segment =>
            (segment.start === updatedSegment.start && segment.speaker === speaker)
                ? updatedSegment
                : segment
        ));

        // Mettre aussi à jour audioSegments pour la cohérence
        setAudioSegments(prev => ({
            ...prev,
            [speaker]: prev[speaker].map(segment =>
                segment.start === updatedSegment.start ? updatedSegment : segment
            )
        }));
    }, []);

    // Nouveau gestionnaire pour recharger l'audio
    const handleAudioReload = useCallback((speaker, updatedSegment) => {
        if (audioPlayerRefs.current[speaker]) {
            // Invalider le cache pour ce segment
            audioPlayerRefs.current[speaker].invalidateCache(updatedSegment.segment_file);

            // Si c'est le segment en cours de lecture, le recharger
            const isCurrentSegment = currentTime >= updatedSegment.start && currentTime < updatedSegment.end;
            if (isCurrentSegment) {
                audioPlayerRefs.current[speaker].reloadCurrentSegment();
            }

            // Mettre à jour audioSegments
            setAudioSegments(prev => ({
                ...prev,
                [speaker]: prev[speaker].map(segment =>
                    segment.start === updatedSegment.start ? updatedSegment : segment
                )
            }));
        }
    }, [currentTime]);

    const handleResetAudioCache = useCallback((speaker) => {
        // Si un speaker est spécifié, reset seulement celui-là
        if (speaker && audioPlayerRefs.current[speaker]?.resetCache) {
            audioPlayerRefs.current[speaker].resetCache();
        }
        // Sinon reset tous les players
        else {
            Object.values(audioPlayerRefs.current).forEach(player => {
                if (player?.resetCache) {
                    player.resetCache();
                }
            });
        }
    }, []);

    const handleSave = useCallback(async (transcriptToSave = null) => {
        try {
            // Utiliser le transcript passé en paramètre s'il existe, sinon utiliser le transcript du state
            const dataToSave = transcriptToSave || transcript;

            console.log("Saving transcript:", dataToSave); // Pour debug

            const response = await axios.post(`${API_CONFIG.BASE_URL}/update_transcript/${projectId}`, dataToSave, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.data?.new_url) {
                console.log("Transcript saved. New URL:", response.data.new_url);
            }

            setMessage('Transcript updated successfully');
        } catch (error) {
            setMessage('Error updating transcript: ' + (error.response?.data?.error || error.message));
        }
    }, [transcript, projectId]);

    const handleExport = useCallback(async (exportType = 'audio') => {
        setIsExporting(true);
        try {
            // Utiliser la nouvelle route pour les exports
            const endpoint = `${API_CONFIG.BASE_URL}/export_audio_video/${projectId}`;

            // Appel API différent selon le type d'export
            const response = await axios.post(
                endpoint,
                { export_type: exportType },
                {
                    responseType: 'blob',
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                }
            );

            // Create a blob from the response data
            const blob = new Blob([response.data], {
                type: exportType === 'video' ? 'video/mp4' : 'audio/wav'
            });

            // Create a temporary URL for the blob
            const url = window.URL.createObjectURL(blob);

            // Create a temporary anchor element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', exportType === 'video' ? 'exported_video.mp4' : 'exported_audio.wav');
            document.body.appendChild(link);
            link.click();

            // Clean up
            link.remove();
            window.URL.revokeObjectURL(url);

            setMessage(`${exportType === 'video' ? 'Video' : 'Audio'} downloaded successfully.`);
        } catch (error) {
            setMessage(`Error exporting ${exportType}: ${error.response?.data?.error || error.message}`);
        } finally {
            setIsExporting(false);
        }
    }, [projectId]);

    const toggleVideoAudio = useCallback(() => {
        setIsVideoAudioEnabled(prev => !prev);
        if (videoRef.current) {
            videoRef.current.muted = !isVideoAudioEnabled;
        }
        Object.values(audioRefs.current).forEach(audio => {
            if (audio) {
                audio.muted = isVideoAudioEnabled;
            }
        });
    }, [isVideoAudioEnabled]);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = !isVideoAudioEnabled;
        }
        Object.values(audioRefs.current).forEach(audio => {
            if (audio) {
                audio.muted = isVideoAudioEnabled;
            }
        });
    }, [isVideoAudioEnabled]);

    const handleTranscriptUpdate = useCallback((updatedTranscript) => {
        setTranscript(updatedTranscript);
    }, []);

    const uniqueSpeakers = [...new Set(transcript.map(item => item.speaker))];

    const components = {
        referenceManager: (
            <ReferenceFilesManager
                projectId={projectId}
                onFilesUpdate={setReferenceFiles}
            />
        ),
        transcriptViewer: (
            <TranscriptViewer
                transcript={transcript}
                currentTime={currentTime}
                onSeek={handleSeek}
                selectedBlock={selectedBlock}
                onBlockSelect={handleBlockSelect}
                onTranscriptUpdate={handleTranscriptUpdate}
                onSave={handleSave}
                projectId={projectId}
                onReloadWaveform={handleWaveformReload}
                onReloadAudio={handleAudioReload} // Nouveau prop
                referenceFiles={referenceFiles}
            />
        ),
        videoPlayer: (
            <VideoPlayer
                videoRef={videoRef}
                currentTime={currentTime}
                duration={duration}
                isPlaying={isPlaying}
                handlePlayPause={handlePlayPause}
                handleSkip={handleSkip}
                videoUrl={videoUrl}
            />
        ),
        timeline: (
            <Timeline
                transcript={transcript}
                currentTime={currentTime}
                duration={duration}
                onSeek={handleSeek}
                selectedBlock={selectedBlock}
                onBlockSelect={handleBlockSelect}
                onTranscriptUpdate={handleTranscriptUpdate}
                onSave={handleSave}
                speakers={uniqueSpeakers}
                mutedTracks={mutedTracks}
                toggleMute={toggleMute}
                projectId={projectId}
                isVideoAudioEnabled={isVideoAudioEnabled}
                toggleVideoAudio={toggleVideoAudio}
                onResetAudioCache={handleResetAudioCache}
                handleAudioReload={handleAudioReload}
                hasVideo={!!videoUrl} // Ajout de cette prop
                audioPlayerRefs={audioPlayerRefs}
                isPlaying={isPlaying}
            />
        ),
        volumeWidget: (
            <AudioVolumePanel
                selectedBlock={selectedBlock}
                transcript={transcript}
                onTranscriptUpdate={handleTranscriptUpdate}
                onSave={handleSave}
                speakerOrder={uniqueSpeakers}
                audioPlayerRefs={audioPlayerRefs}
            />
        ),
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', position: 'relative' }}>
            {transcript.length > 0 && assetsLoaded ? (
                // Affichage normal avec le header quand le transcript est chargé
                <>
                    <Box sx={{ height: '60px', width: '100%', position: 'absolute', top: 0, left: 0, zIndex: 1000 }}>
                        <Header
                            onSave={handleSave}
                            onExport={handleExport}
                            isExporting={isExporting}
                            projectName={`Project ${projectId}`}
                            language="en"
                            hasVideo={!!videoUrl}
                        />
                    </Box>
                    <Box sx={{ position: 'absolute', top: '60px', left: 0, right: 0, bottom: 0, overflow: 'hidden' }}>
                        {message && (
                            <Box sx={{ p: 2, bgcolor: 'error.main', color: 'error.contrastText' }}>
                                {message}
                            </Box>
                        )}
                        <FlexibleLayout components={components} />
                        <Box>
                            {uniqueSpeakers.map(speaker => (
                                <Box key={speaker} sx={{ mr: 2, mb: 2 }}>
                                    <AudioPlayer
                                        speakerId={speaker}
                                        segments={audioSegments[speaker]}
                                        transcript={transcript}
                                        isPlaying={isPlaying}
                                        currentTime={currentTime}
                                        muted={mutedTracks[speaker]}
                                        projectId={projectId}
                                        ref={el => {
                                            audioPlayerRefs.current[speaker] = el;
                                            audioRefs.current[speaker] = el;
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </>
            ) : (
                // Écran de chargement en plein écran
                <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                    <LoadingScreen />
                </Box>
            )}
        </Box>
    );
}

export default MainPage;