import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Button, IconButton, List, ListItem, ListItemText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { API_CONFIG } from '../../config/constants';

const ReferenceFilesManager = ({ projectId, onReferenceSelect, onFilesUpdate }) => {
    const [referenceFiles, setReferenceFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);

    const PRIMARY_COLOR = '#6366f1';
    const SECONDARY_COLOR = '#4f46e5';

    useEffect(() => {
        fetchReferenceFiles();
    }, [projectId]);

    const fetchReferenceFiles = async () => {
        try {
            const response = await fetch(
                `${API_CONFIG.BASE_URL}/projects/${projectId}/reference_files`,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            if (response.ok) {
                const data = await response.json();
                setReferenceFiles(data.reference_files);
                onFilesUpdate?.(data.reference_files);
            }
        } catch (error) {
            console.error('Error fetching reference files:', error);
        }
    };

    const handleFileUpload = async (files) => {
        if (!files || files.length === 0) return;

        setIsUploading(true);

        for (const file of files) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                const response = await fetch(
                    `${API_CONFIG.BASE_URL}/projects/${projectId}/reference_files`,
                    {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        },
                        body: formData,
                    }
                );

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Upload failed');
                }
            } catch (error) {
                console.error(`Error uploading file ${file.name}:`, error);
            }
        }

        await fetchReferenceFiles();
        setIsUploading(false);
    };

    const handleDeleteFile = async (fileId) => {
        try {
            const response = await fetch(
                `${API_CONFIG.BASE_URL}/delete_file/${projectId}/${fileId}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            if (response.ok) {
                setReferenceFiles(files => files.filter(f => f.id !== fileId));
                if (selectedFile === fileId) {
                    setSelectedFile(null);
                    onReferenceSelect?.(null);
                }
            }
        } catch (error) {
            console.error('Error deleting reference file:', error);
        }
    };

    const handleReferenceSelect = (file) => {
        setSelectedFile(file.id);
        onReferenceSelect?.(file);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const files = [...e.dataTransfer.files].filter(file =>
            ['.wav', '.mp3', '.m4a', '.aac'].some(ext =>
                file.name.toLowerCase().endsWith(ext)
            )
        );

        if (files.length > 0) {
            await handleFileUpload(files);
        }
    };

    return (
        <Box sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
        }}>
            <Box
                onDragEnter={handleDragEnter}
                onDragOver={(e) => e.preventDefault()}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                    p: 2,
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#555',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#333',
                        borderRadius: '4px',
                    },
                }}
            >
                <Box
                    sx={{
                        mb: 2,
                        p: 3,
                        border: '2px dashed',
                        borderColor: isDragging ? PRIMARY_COLOR : 'rgba(255,255,255,0.23)',
                        borderRadius: '4px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: isDragging ? `${PRIMARY_COLOR}0D` : 'rgba(32, 33, 36, 0.9)',
                        transition: 'all 0.2s ease',
                        cursor: 'pointer',
                    }}
                    onClick={() => fileInputRef.current?.click()}
                >
                    <input
                        ref={fileInputRef}
                        type="file"
                        hidden
                        multiple
                        accept=".wav,.mp3,.m4a,.aac"
                        onChange={(e) => handleFileUpload(e.target.files)}
                    />
                    <CloudUploadIcon
                        sx={{
                            fontSize: 48,
                            color: isDragging ? PRIMARY_COLOR : 'rgba(255,255,255,0.7)',
                            mb: 1
                        }}
                    />
                    <Typography variant="body1" sx={{ color: 'white', mb: 1 }}>
                        {isUploading ? 'Uploading...' : 'Drop audio files here or click to upload'}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.6)' }}>
                        Supported formats: WAV, MP3, M4A, AAC
                    </Typography>
                </Box>

                <List>
                    {referenceFiles.map((file) => (
                        <ListItem
                            key={file.id}
                            sx={{
                                mb: 1,
                                bgcolor: selectedFile === file.id ? `${PRIMARY_COLOR}1A` : 'rgba(32, 33, 36, 0.9)',
                                borderRadius: '4px',
                                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                                transition: 'all 0.2s ease',
                                cursor: 'pointer',
                                '&:hover': {
                                    bgcolor: `${PRIMARY_COLOR}0D`,
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
                                },
                            }}
                            onClick={() => handleReferenceSelect(file)}
                        >
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                pr: 6 // Pour laisser de la place pour le bouton delete
                            }}>
                                <Box
                                    sx={{
                                        width: 32,
                                        height: 32,
                                        borderRadius: '50%',
                                        bgcolor: `${PRIMARY_COLOR}1A`,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mr: 2,
                                    }}
                                >
                                    <MusicNoteIcon sx={{ fontSize: 18, color: PRIMARY_COLOR }} />
                                </Box>
                                <ListItemText
                                    primary={
                                        <Typography sx={{ color: 'white', fontSize: '0.875rem' }}>
                                            {file.filename}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.6)', fontSize: '0.75rem' }}>
                                            {formatDate(file.created_at)}
                                        </Typography>
                                    }
                                />
                            </Box>
                            <IconButton
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteFile(file.id);
                                }}
                                sx={{
                                    position: 'absolute',
                                    right: 12,
                                    color: 'rgba(255,255,255,0.7)',
                                    '&:hover': { color: '#ff4d4f' }
                                }}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </ListItem>
                    ))}
                </List>

                {referenceFiles.length === 0 && !isDragging && (
                    <Typography sx={{
                        color: 'rgba(255,255,255,0.6)',
                        textAlign: 'center',
                        mt: 4
                    }}>
                        No reference files uploaded
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default ReferenceFilesManager;