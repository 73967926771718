import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import SubscriptionService from '../../services/subscription.service';
import './PricingPage.css';
import { authService } from '../../services/auth.service';

const PricingPage = () => {
    const [isAnnual, setIsAnnual] = useState(true);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [loading, setLoading] = useState({
        initialLoad: true,
        plans: {}
    });
    const [cancelLoading, setCancelLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        loadCurrentSubscription();
    }, []);

    // Fonction pour effacer les messages après un certain délai
    const clearMessages = (delay = 5000) => {
        setTimeout(() => {
            setError(null);
            setSuccessMessage(null);
        }, delay);
    };

    const getActualPlanId = (baseId) => {
        if (baseId === 'free') return 'free';
        return isAnnual ? `${baseId}-yearly` : `${baseId}-monthly`;
    };

    const plans = [
        {
            baseId: 'free',
            name: "Gratuit",
            monthlyPrice: 0,
            yearlyPrice: 0,
            features: [
                "Accès aux fonctionnalités de base",
                "100 coins par mois",
                "jusqu'à 100 Mo / vidéo",
                "jsqu'à 1 minutes / vidéo",
                "Pas de carte bancaire requise"
            ],
            buttonText: currentPlan === 'free' ? "Plan actuel" : "Commencer gratuitement",
            buttonStyle: "secondary",
            popular: false
        },
        {
            baseId: 'classic',
            name: "Classique",
            monthlyPrice: 34.99,
            yearlyPrice: 24.99,
            features: [
                "Accès aux fonctionnalités de base",
                "Sans filigrane",
                "6000 coins par mois",
                "jusqu'à 1000 Mo / vidéo",
                "jusqu'à 10 minutes",
                "Support par email",
                isAnnual ? "Engagement 12 mois" : "Sans engagement",
            ].filter(Boolean),
            buttonText: currentPlan === getActualPlanId('classic') ? "Plan actuel" : "Commencer maintenant",
            buttonStyle: "secondary",
            popular: false
        },
        {
            baseId: 'pro',
            name: "Pro",
            monthlyPrice: 99.99,
            yearlyPrice: 74.99,
            features: [
                "Toutes les fonctionnalités Classic",
                "Sans filigrane",
                "30000 coins par mois",
                "jusqu'à 2000 Mo / vidéo",
                "jusqu'à 30 minutes",
                "Support prioritaire",
                isAnnual ? "Engagement 12 mois" : "Sans engagement",

            ].filter(Boolean),
            buttonText: currentPlan === getActualPlanId('pro') ? "Plan actuel" : "Commencer maintenant",
            buttonStyle: "primary",
            popular: true
        },
        {
            baseId: 'enterprise',
            name: "Entreprise",
            price: "Sur mesure",
            features: [
                "Solution personnalisée",
                "Accompagnement dédié",
                "Support prioritaire 24/7",
                "Fonctionnalités sur mesure"
            ],
            buttonText: "Contacter nous",
            buttonStyle: "secondary",
            popular: false
        }
    ];

    const loadCurrentSubscription = async () => {
        // Vérifie d'abord si l'utilisateur est réellement connecté (avec un username)
        const hasUsername = localStorage.getItem('username');
        console.log(hasUsername);
        if (!hasUsername) {
            setLoading(prev => ({ ...prev, initialLoad: false }));
            setCurrentPlan(null);
            return;
        }

        try {
            const subscription = await SubscriptionService.getCurrentSubscription();
            if (subscription) {
                console.log(subscription.planId);
                setCurrentPlan(subscription.planId);
            }
        } catch (error) {
            console.error('Error loading subscription:', error);
            // On ne définit pas d'erreur ici car ce n'est pas grave si l'utilisateur n'a pas de plan
        } finally {
            setLoading(prev => ({ ...prev, initialLoad: false }));
        }
    };

    async function fetchCurrentSubscription() {
        try {
            const subscription = await SubscriptionService.getCurrentSubscription();

            // Si l'abonnement est en cours d'annulation (active_until_end)
            // vérifier s'il y a un abonnement en attente
            if (subscription && subscription.status === 'active_until_end') {
                try {
                    // Vous devrez créer cette API endpoint
                    const pendingPlan = await SubscriptionService.getPendingSubscription();
                    if (pendingPlan) {
                        subscription.pendingPlan = pendingPlan.planId;
                    }
                } catch (error) {
                    console.error("Error fetching pending subscription:", error);
                }
            }

            setCurrentPlan(subscription);
            return subscription;
        } catch (error) {
            console.error("Error fetching subscription:", error);
            return null;
        }
    }

    const handleSubscribe = async (baseId) => {
        if (baseId === 'enterprise') {
            window.location.href = '/demo';
            return;
        }

        if (!authService.isAuthenticated()) {
            navigate('/login', { state: { from: '/pricing' } });
            return;
        }

        const actualPlanId = getActualPlanId(baseId);

        try {
            // Activer le chargement uniquement pour ce plan
            setLoading(prev => ({
                ...prev,
                plans: {
                    ...prev.plans,
                    [baseId]: true
                }
            }));
            setError(null);
            setSuccessMessage(null);

            // Cas spécial pour le plan gratuit
            if (baseId === 'free') {
                const result = await SubscriptionService.subscribeToFreePlan();
                if (result.message) {
                    setSuccessMessage(result.message);
                    clearMessages(8000); // Message affiché plus longtemps
                }

                // Si une redirection est nécessaire
                if (result.redirect) {
                    setTimeout(() => {
                        window.location.href = result.redirect;
                    }, 2000); // Délai court pour voir le message
                }

                return;
            }

            const subscription = await SubscriptionService.getCurrentSubscription();

            // Si l'utilisateur a déjà un abonnement actif
            if (subscription.status !== 'none') {
                if (subscription.planId === actualPlanId) {
                    setError("Vous avez déjà souscrit à cet abonnement");
                    clearMessages();
                    return;
                }

                const result = await SubscriptionService.updateSubscription(actualPlanId);

                // Si un message est retourné (cas de downgrade)
                if (result && result.message) {
                    setSuccessMessage(result.message);
                    clearMessages(8000);

                    // Si une redirection est nécessaire
                    if (result.redirect) {
                        setTimeout(() => {
                            window.location.href = result.redirect;
                        }, 2000);
                    }
                }
            } else {
                const result = await SubscriptionService.subscribe(actualPlanId);

                // Si un message est retourné
                if (result && result.message) {
                    setSuccessMessage(result.message);
                    clearMessages(8000);

                    // Si une redirection est nécessaire
                    if (result.redirect) {
                        setTimeout(() => {
                            window.location.href = result.redirect;
                        }, 2000);
                    }
                }
            }

            await loadCurrentSubscription();
        } catch (error) {
            setError(error.message || "Erreur lors de la souscription");
            clearMessages();
        } finally {
            // Désactiver le chargement uniquement pour ce plan
            setLoading(prev => ({
                ...prev,
                plans: {
                    ...prev.plans,
                    [baseId]: false
                }
            }));
        }
    };

    const faqs = [
        {
            question: "Comment fonctionne la facturation ?",
            answer: "La facturation est mensuelle. L'engagement annuel vous permet de bénéficier d'une remise de 20% sur le tarif mensuel."
        },
        {
            question: "Puis-je changer de forfait ?",
            answer: "Vous pouvez upgrader ou downgrader votre forfait mensuel à tout moment. Les ajustements de facturation seront calculés au prorata."
        },
        {
            question: "Y a-t-il une période d'essai ?",
            answer: "Oui, notre forfait \"gratuit\" inclu un essai sans engagement. Vous pouvez tester toutes les fonctionnalités avant de vous engager."
        },
        {
            question: "Quels sont les formats d'export supportés ?",
            answer: "Nous supportons l'export en MP3, WAV et MP4. Les forfaits Pro et Entreprise permettent également des formats personnalisés."
        }
    ];

    function renderPendingDowngradeNotice(subscription) {
        if (subscription && subscription.status === 'active_until_end' && subscription.cancellationInfo) {
            const { willEndOn, monthsRemaining } = subscription.cancellationInfo;
            const endDate = new Date(willEndOn).toLocaleDateString();
            const timeRemaining = monthsRemaining > 1
                ? `${monthsRemaining} mois`
                : monthsRemaining === 1
                    ? '1 mois'
                    : 'quelques jours';

            const hasPendingPlan = subscription.pendingPlan;
            const pendingPlanName = hasPendingPlan
                ? `sera changé vers ${hasPendingPlan}`
                : 'sera converti au plan Gratuit';

            return (
                <div className="pricing-page__notice">
                    <div className="pricing-page__notice-content">
                        <i className="fas fa-info-circle"></i>
                        <p>
                            Votre abonnement actuel est programmé pour se terminer le <strong>{endDate}</strong> (dans {timeRemaining}).
                            Après cette date, votre compte {pendingPlanName}.
                            Vous pouvez toujours mettre à niveau votre plan à tout moment.
                        </p>
                    </div>
                </div>
            );
        }
        return null;
    }

    return (
        <div className="pricing-page">
            <div className="pricing-page__glass-bg">
                <div className="pricing-page__blob pricing-page__blob-1"></div>
                <div className="pricing-page__blob pricing-page__blob-2"></div>
            </div>

            <Navbar />

            <div className="pricing-page__header">
                <h1 className="pricing-page__title">Des forfaits adaptés à vos besoins</h1>
                <p className="pricing-page__subtitle">
                    Choisissez le plan qui correspond le mieux à votre utilisation et développez votre audience internationale
                </p>

                {error && (
                    <div className="pricing-page__error">
                        <i className="fas fa-exclamation-circle"></i>
                        {error}
                    </div>
                )}

                {successMessage && (
                    <div className="pricing-page__success">
                        <i className="fas fa-check-circle"></i>
                        {successMessage}
                    </div>
                )}

                <div className="pricing-page__toggle">
                    <button
                        className={`pricing-page__toggle-button ${!isAnnual ? 'pricing-page__toggle-button--active' : ''}`}
                        onClick={() => setIsAnnual(false)}
                    >
                        Mensuel
                    </button>
                    <button
                        className={`pricing-page__toggle-button ${isAnnual ? 'pricing-page__toggle-button--active' : ''}`}
                        onClick={() => setIsAnnual(true)}
                    >
                        Annuel (-20%)
                    </button>
                </div>
            </div>

            <div className="pricing-page__grid">
                {plans.map((plan, index) => (
                    <div
                        key={index}
                        className={`pricing-page__card ${plan.popular ? 'pricing-page__card--popular' : ''}`}
                    >
                        {plan.popular && (
                            <div className="pricing-page__popular-badge">Plus populaire</div>
                        )}
                        <h3 className="pricing-page__card-title">{plan.name}</h3>
                        <div className={`pricing-page__price ${plan.baseId === 'enterprise' ? 'pricing-page__price--custom' : ''}`}>
                            {plan.price === "Sur mesure" ? (
                                plan.price
                            ) : (
                                <>
                                    {isAnnual ? plan.yearlyPrice : plan.monthlyPrice}€ <span>/mois</span>
                                </>
                            )}
                        </div>
                        <ul className="pricing-page__features">
                            {plan.features.map((feature, featureIndex) => (
                                <li key={featureIndex} className="pricing-page__feature">
                                    <i className="fas fa-check"></i>
                                    {feature}
                                </li>
                            ))}
                        </ul>
                        <button
                            onClick={() => handleSubscribe(plan.baseId)}
                            disabled={loading.plans[plan.baseId] || (currentPlan === getActualPlanId(plan.baseId))}
                            className={`
                                pricing-page__button 
                                pricing-page__button--${plan.buttonStyle}
                                ${loading.plans[plan.baseId] ? 'pricing-page__button--loading' : ''}
                            `}
                        >
                            {plan.baseId === 'enterprise'
                                ? "Contacter nous"
                                : loading.plans[plan.baseId]
                                    ? 'Chargement...'
                                    : currentPlan === getActualPlanId(plan.baseId)
                                        ? "Abonnement actuel"
                                        : currentPlan
                                            ? "Changer pour ce plan"
                                            : "Commencer maintenant"
                            }
                        </button>
                    </div>
                ))}
            </div>

            <section className="pricing-page__faq">
                <h2 className="pricing-page__faq-title">Questions fréquentes</h2>
                {faqs.map((faq, index) => (
                    <div key={index} className="pricing-page__faq-item">
                        <h3 className="pricing-page__faq-question">{faq.question}</h3>
                        <p className="pricing-page__faq-answer">{faq.answer}</p>
                    </div>
                ))}
            </section>

            <Footer />
        </div>
    );
};

export default PricingPage;