import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { Mic, Waves } from 'lucide-react';

const LoadingScreen = () => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: '#1E1E1E',
                gap: 3
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2
                }}
            >
                <Box
                    sx={{
                        width: 80,
                        height: 80,
                        borderRadius: '50%',
                        bgcolor: 'rgba(99, 102, 241, 0.1)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        animation: 'pulse 1.5s infinite ease-in-out',
                        '@keyframes pulse': {
                            '0%': { opacity: 0.6, transform: 'scale(0.95)' },
                            '50%': { opacity: 1, transform: 'scale(1.05)' },
                            '100%': { opacity: 0.6, transform: 'scale(0.95)' }
                        }
                    }}
                >
                    <Waves size={40} color="#6366f1" />
                </Box>

                <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold', mb: 1 }}>
                    Loading your project
                </Typography>

                <Box sx={{ position: 'relative', width: 300, textAlign: 'center' }}>
                    <CircularProgress
                        size={24}
                        thickness={4}
                        sx={{ color: '#6366f1', mb: 2 }}
                    />

                    <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)', fontWeight: 'light', mt: 2 }}>
                        Preparing your audio tracks and transcript...
                    </Typography>
                </Box>
            </Box>

            <Box
                sx={{
                    width: '60%',
                    maxWidth: 500,
                    mt: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    opacity: 0.5
                }}
            >

            </Box>
        </Box>
    );
};

export default LoadingScreen;