import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    const footerLinks = {
        produit: [
            { name: 'Fonctionnalités', path: '/features' },
            { name: 'Démo', path: '/demo' },
            { name: 'Tarifs', path: '/pricing' }
        ],
        légal: [
            { name: 'Confidentialité', path: '/privacy' },
            { name: 'CGU', path: '/terms' },
            { name: 'Cookies', path: '/cookies' },
            { name: 'Mentions légales', path: '/legal' }
        ]
    };

    const socialLinks = [
        { icon: 'twitter', url: 'https://x.com/sumfy_' },
        { icon: 'linkedin', url: 'https://linkedin.com/company/sumfy' },
        { icon: 'instagram', url: 'https://www.instagram.com/sumfy_translator?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==' },
    ];

    const handleClick = () => {
        window.scrollTo({ top: 0 });
    };

    const CustomLink = ({ to, children, className }) => (
        <Link to={to} className={className} onClick={handleClick}>
            {children}
        </Link>
    );

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-main">
                    <div className="footer-brand">
                        <Link to="/" className="footer-logo">
                            Sumfy
                        </Link>
                        <p className="footer-tagline">
                            Retrouvez-nous sur nos réseaux sociaux
                        </p>
                        <div className="social-links">
                            {socialLinks.map((social, index) => (
                                <a
                                    key={index}
                                    href={social.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label={`Suivez-nous sur ${social.icon}`}
                                >
                                    <i className={`fab fa-${social.icon}`}></i>
                                </a>
                            ))}
                        </div>
                    </div>

                    <div className="footer-links">
                        {Object.entries(footerLinks).map(([category, links]) => (
                            <div key={category} className="footer-column">
                                <h4>{category.charAt(0).toUpperCase() + category.slice(1)}</h4>
                                <ul>
                                    {links.map((link, index) => (
                                        <li key={index}>
                                            <CustomLink to={link.path}>
                                                {link.name}
                                            </CustomLink>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="footer-bottom-content">
                        <div className="footer-bottom-links">
                        </div>
                        <div className="footer-copyright">
                            <CustomLink to="/">© 2025 Sumfy. Tous droits réservés.</CustomLink>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;