import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { projectService } from '../../services/project.service.js';
import './NewProjectModalV2.css';
import FingerprintService from '../../services/fingerprint.service';

// Constants
const FILE_SIZE_LIMITS = {
    'free': 100 * 1024 * 1024, // 100 MB
    'classic': 1000 * 1024 * 1024, // 1000 MB
    'pro': 2000 * 1024 * 1024, // 2000 MB
    'default': 100 * 1024 * 1024 // Valeur par défaut (Free)
};
const ACCEPTED_FILE_TYPES = ['audio/', 'video/', '.wav'];
const MIN_PROJECT_NAME_LENGTH = 3;
const MAX_PROJECT_NAME_LENGTH = 50;
const TOKENS_PER_MINUTE = 100; // 100 tokens par minute d'audio

// Validation Functions
const validateProjectName = (name) => {
    if (!name.trim()) return 'Le nom du projet est requis';
    if (name.length < MIN_PROJECT_NAME_LENGTH) {
        return `Le nom du projet doit contenir au moins ${MIN_PROJECT_NAME_LENGTH} caractères`;
    }
    if (name.length > MAX_PROJECT_NAME_LENGTH) {
        return `Le nom du projet ne peut pas dépasser ${MAX_PROJECT_NAME_LENGTH} caractères`;
    }
    if (/[<>:"\/\\|?*]/.test(name)) {
        return 'Le nom du projet contient des caractères non autorisés';
    }
    return null;
};

const validateFiles = (files, maxFileSize, userPlan) => {
    if (files.length === 0) return 'Veuillez ajouter au moins un fichier';

    const planBase = userPlan ? userPlan.split('-')[0] : 'default';
    const limitInMB = (FILE_SIZE_LIMITS[planBase] || FILE_SIZE_LIMITS.default) / (1024 * 1024);

    for (const file of files) {
        if (file.size > maxFileSize) {
            return `Le fichier dépasse la taille maximale autorisée (${limitInMB} MB) pour votre plan ${planBase}`;
        }

        const isValidType = ACCEPTED_FILE_TYPES.some(type =>
            file.type.startsWith(type) || (type.startsWith('.') && file.name.endsWith(type))
        );

        if (!isValidType) {
            return `Le fichier "${file.name}" n'est pas dans un format audio/vidéo accepté`;
        }
    }
    return null;
};


const validateLanguages = (languages) => {
    return languages.length === 0 ? 'Veuillez sélectionner au moins une langue' : null;
};

// Nouvelle fonction pour estimer la durée audio des fichiers
const estimateAudioDuration = async (files) => {
    let totalDurationInSeconds = 0;

    for (const file of files) {
        if (file.type.startsWith('audio/') || file.type.startsWith('video/') || file.name.endsWith('.wav')) {
            try {
                // Créer une URL pour le fichier
                const url = URL.createObjectURL(file);

                // Créer un élément audio/video pour obtenir la durée
                const element = file.type.startsWith('audio/') ? new Audio() : document.createElement('video');

                // Attendre que les métadonnées soient chargées
                const duration = await new Promise((resolve, reject) => {
                    element.onloadedmetadata = () => resolve(element.duration);
                    element.onerror = () => reject(new Error(`Impossible de charger ${file.name}`));
                    element.src = url;
                });

                totalDurationInSeconds += duration;

                // Libérer l'URL
                URL.revokeObjectURL(url);
            } catch (error) {
                console.error(`Erreur lors de l'estimation de la durée pour ${file.name}:`, error);
                // En cas d'erreur, estimer à 5 minutes par fichier pour être prudent
                totalDurationInSeconds += 300;
            }
        }
    }

    return totalDurationInSeconds;
};

// Estimer les tokens nécessaires
const estimateRequiredTokens = (durationInSeconds, numberOfLanguages) => {
    // Convertir en minutes et arrondir à la minute supérieure
    const durationInMinutes = Math.ceil(durationInSeconds / 60);
    // Multiplier par le coût par minute et par le nombre de langues
    return durationInMinutes * TOKENS_PER_MINUTE * numberOfLanguages;
};

// Component: FileDropZone
const FileDropZone = ({ onFilesDrop, userPlan }) => {
    const [isDragging, setIsDragging] = useState(false);
    const planBase = userPlan ? userPlan.split('-')[0] : 'default';
    const limitInMB = (FILE_SIZE_LIMITS[planBase] || FILE_SIZE_LIMITS.default) / (1024 * 1024);

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        onFilesDrop(e.dataTransfer.files);
    };

    const handleClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.accept = 'audio/*,video/*,.wav';
        input.onchange = (e) => onFilesDrop(e.target.files);
        input.click();
    };

    return (
        <div
            className={`file-drop-zone ${isDragging ? 'dragging' : ''}`}
            onDrop={handleDrop}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onClick={handleClick}
            role="button"
            tabIndex={0}
            aria-label="Zone de dépôt de fichiers"
        >
            <i className="fas fa-cloud-upload-alt file-drop-icon" />
            <p>
                Glissez vos fichiers audio/vidéo ici ou <strong>parcourir</strong>
            </p>
            <span className="file-drop-help">
                Formats acceptés: audio et vidéo • {limitInMB} MB max
            </span>
        </div>
    );
};

// Component: FileList
const FileList = ({ files, onRemoveFile }) => {
    const getFileIcon = (file) => {
        if (file.type.startsWith('audio/')) return 'fa-file-audio';
        if (file.type.startsWith('video/')) return 'fa-file-video';
        return 'fa-file';
    };

    return (
        <div className="file-list" role="list">
            {files.map((file, index) => (
                <div key={index} className="file-item" role="listitem">
                    <i className={`fas ${getFileIcon(file)}`} aria-hidden="true" />
                    <span className="file-name">{file.name}</span>
                    <span className="file-size">
                        {(file.size / (1024 * 1024)).toFixed(2)} MB
                    </span>
                    <button
                        type="button"
                        className="action-btn btn-secondary"
                        onClick={() => onRemoveFile(index)}
                        aria-label={`Supprimer le fichier ${file.name}`}
                    >
                        <i className="fas fa-times" />
                    </button>
                </div>
            ))}
            {files.length === 0 && (
                <div className="file-list-empty" role="status">
                    Aucun fichier sélectionné
                </div>
            )}
        </div>
    );
};

// Component: LanguageGrid
const LanguageGrid = ({ selectedLanguages, onToggle, allLanguages }) => {
    return (
        <div className="language-grid" role="group" aria-label="Sélection des langues">
            {allLanguages.map(language => (
                <div
                    key={language.code}
                    className={`language-option ${selectedLanguages.includes(language.code) ? 'selected' : ''}`}
                    onClick={() => onToggle(language.code)}
                    role="checkbox"
                    aria-checked={selectedLanguages.includes(language.code)}
                    tabIndex={0}
                >
                    <input
                        type="checkbox"
                        hidden
                        checked={selectedLanguages.includes(language.code)}
                        readOnly
                    />
                    <i className="fas fa-check" aria-hidden="true" />
                    <span>{language.name}</span>
                </div>
            ))}
        </div>
    );
};




// Version avec ratio d'utilisation du composant TokenDisplay
const TokenDisplay = ({ estimatedTokens, userCoins }) => {
    // Calcul du pourcentage utilisé
    const usagePercentage = userCoins > 0
        ? Math.min(Math.round((estimatedTokens / userCoins) * 100), 100)
        : 100;

    // Détermine si l'utilisateur a suffisamment de tokens
    const isInsufficient = estimatedTokens > userCoins;

    return (
        <div className={`tokens-dashboard ${isInsufficient ? 'tokens-insufficient' : ''}`}>
            {/* Affichage des tokens avec ratio */}
            <div className="tokens-available">
                <i className="fas fa-coins"></i>
                <span className="tokens-available-text">
                    <span className="tokens-available-count">{estimatedTokens} {" "}/{" "}{userCoins} tokens</span>
                </span>
            </div>

            {/* Barre de progression */}
            <div className="tokens-progress-container">
                <div className="tokens-progress-bar">
                    <div
                        className="tokens-progress-fill"
                        style={{ width: `${usagePercentage}%` }}
                    ></div>
                </div>
            </div>

            {/* Tooltip d'information */}
            <div className="tokens-info-tooltip">
                <i className="fas fa-info-circle"></i>
                <div className="tokens-tooltip-content">
                    Tokens requis / Tokens disponibles Le coût dépend de la durée audio et du nombre de langues.
                    {isInsufficient ? " Solde insuffisant pour ce projet." : " Solde suffisant pour ce projet."}
                </div>
            </div>
        </div>
    );
};


// Component: Export Options
const ExportOptions = ({ onClose, onExport, isCheckingVideo, hasVideo, isExporting }) => {
    return (
        <div className="export-options">
            <h3>Options d'exportation</h3>
            <p>Choisissez le format que vous souhaitez exporter :</p>

            <div className="export-buttons">
                <button
                    className="btn-primary"
                    onClick={() => onExport('audio')}
                    disabled={isExporting}
                >
                    {isExporting === 'audio' ? (
                        <><i className="fas fa-spinner fa-spin"></i> Exportation...</>
                    ) : (
                        <>Audio uniquement <i className="fas fa-music"></i></>
                    )}
                </button>

                <button
                    className="btn-primary"
                    onClick={() => onExport('video')}
                    disabled={isExporting || isCheckingVideo || !hasVideo}
                >
                    {isExporting === 'video' ? (
                        <><i className="fas fa-spinner fa-spin"></i> Exportation...</>
                    ) : isCheckingVideo ? (
                        <><i className="fas fa-spinner fa-spin"></i> Vérification...</>
                    ) : !hasVideo ? (
                        <>Vidéo non disponible <i className="fas fa-ban"></i></>
                    ) : (
                        <>Vidéo <i className="fas fa-film"></i></>
                    )}
                </button>
            </div>

            <button
                className="btn-secondary mt-4"
                onClick={onClose}
                disabled={isExporting}
            >
                Annuler
            </button>
        </div>
    );
};

const Step = ({ number, label, isActive, isCompleted }) => {
    const className = `step ${isActive ? 'active' : ''} ${isCompleted ? 'completed' : ''}`;
    return (
        <div className={className}>
            <div className="step-number">
                {!isCompleted && <span>{number}</span>}
            </div>
            <div className="step-label">{label}</div>
        </div>
    );
};

// Component: StepContent
const StepContent = ({
    step,
    formData,
    setFormData,
    onRemoveFile,
    allLanguages,
    setError,
    maxFileSize,
    estimatedTokens,
    userCoins,
    userPlan
}) => {

    const handleFileChange = async (files) => {
        const newFiles = Array.from(files);
        let errorMessage = null;

        for (const file of newFiles) {
            if (file.size > maxFileSize) {
                const limitInMB = maxFileSize / (1024 * 1024);
                const planBase = userPlan ? userPlan.split('-')[0] : 'default';
                errorMessage = `Le fichier dépasse la taille maximale autorisée (${limitInMB} MB) pour votre plan ${planBase}`;
                break;
            }

            const isValidType = ACCEPTED_FILE_TYPES.some(type =>
                file.type.startsWith(type) || (type.startsWith('.') && file.name.endsWith(type))
            );

            if (!isValidType) {
                errorMessage = `Le fichier "${file.name}" n'est pas dans un format audio/vidéo accepté`;
                break;
            }
        }

        if (errorMessage) {
            setError(errorMessage);
            return;
        }

        setFormData(prev => ({
            ...prev,
            files: [...prev.files, ...newFiles]
        }));
        setError(null);
    };

    const toggleLanguage = (languageCode) => {
        setFormData(prev => ({
            ...prev,
            languages: prev.languages.includes(languageCode)
                ? prev.languages.filter(code => code !== languageCode)
                : [...prev.languages, languageCode]
        }));
    };

    switch (step) {
        case 1:
            return (
                <div className="step-content active" data-step="1">
                    <div className="form-group">
                        <label className="form-label">
                            Nom du projet
                            <span className="required">*</span>
                        </label>
                        <input
                            type="text"
                            className="form-input"
                            placeholder="Entrez le nom du projet"
                            value={formData.name}
                            onChange={(e) => setFormData(prev => ({
                                ...prev,
                                name: e.target.value
                            }))}
                            maxLength={MAX_PROJECT_NAME_LENGTH}
                            aria-required="true"
                        />
                        <span className="form-help">
                            <span className="char-count">
                                {formData.name.length}/{MAX_PROJECT_NAME_LENGTH}
                            </span>
                        </span>
                    </div>
                </div>
            );

        case 2:
            return (
                <div className="step-content active" data-step="2">
                    <div className="form-group">
                        <label className="form-label">
                            Fichiers du projet
                            <span className="required">*</span>
                        </label>
                        <FileDropZone onFilesDrop={handleFileChange} userPlan={userPlan} />
                        <FileList
                            files={formData.files}
                            onRemoveFile={onRemoveFile}
                        />
                    </div>
                </div>
            );

        case 3:
            return (
                <div className="step-content active" data-step="3">
                    <div className="form-group">
                        <label className="form-label">
                            Langues cibles
                            <span className="required">*</span>
                        </label>
                        <p className="form-help">
                            Sélectionnez les langues pour votre projet
                        </p>
                        <LanguageGrid
                            selectedLanguages={formData.languages}
                            onToggle={toggleLanguage}
                            allLanguages={allLanguages}
                        />
                    </div>
                </div>
            );

        default:
            return null;
    }
};


// NEW COMPONENT: Language Selection for project actions (download/edit)
const LanguageSelectionModal = ({ project, isOpen, onClose, onLanguageSelect, action, allLanguages }) => {
    const [videoAvailability, setVideoAvailability] = useState({});
    const [checkingVideo, setCheckingVideo] = useState({});
    const [exporting, setExporting] = useState(null);

    // Vérifier la disponibilité des vidéos pour chaque projet lorsque le modal s'ouvre
    useEffect(() => {
        if (isOpen && project && action === 'download' && project.projects) {
            checkVideoAvailability();
        }
    }, [isOpen, project, action]);

    const checkVideoAvailability = async () => {
        if (!project?.projects) return;

        // Initialiser l'état de vérification pour chaque projet
        const initialChecking = {};
        project.projects.forEach(proj => {
            initialChecking[proj.id] = true;
        });
        setCheckingVideo(initialChecking);

        // Vérifier chaque projet
        const availability = {};
        for (const proj of project.projects) {
            try {
                const result = await projectService.checkVideoAvailability(proj.id);
                availability[proj.id] = result.available;
            } catch (error) {
                console.error(`Erreur lors de la vérification de la vidéo pour ${proj.id}:`, error);
                availability[proj.id] = false;
            } finally {
                // Marquer ce projet spécifique comme vérifié
                setCheckingVideo(prev => ({
                    ...prev,
                    [proj.id]: false
                }));
            }
        }

        setVideoAvailability(availability);
    };

    const handleExport = async (projectId, languageCode, exportType) => {
        // Marquer comme en cours d'exportation
        setExporting({ projectId, type: exportType });

        try {
            await onLanguageSelect(projectId, languageCode, exportType);
        } catch (error) {
            console.error(`Erreur d'exportation ${exportType}:`, error);
        } finally {
            setExporting(null);
        }
    };

    if (!isOpen || !project) return null;

    const handleBackdropClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const actionTitle = action === 'download' ? 'Télécharger' : 'Éditer';

    return (
        <div className="npmodal__language_select_modal" onClick={handleBackdropClick}>
            <div className="npmodal__language_select_content">
                <div className="npmodal__language_select_header">
                    <h2 className="npmodal__language_select_title">{actionTitle} le projet</h2>
                    <button className="npmodal__language_select_close" onClick={onClose}>
                        <i className="fas fa-times"></i>
                    </button>
                </div>

                <div className="npmodal__language_select_description">
                    <div className="npmodal__language_select_info">
                        <i className="fas fa-info-circle"></i>
                        <p>Sélectionnez la langue que vous souhaitez {action === 'download' ? 'télécharger' : 'éditer'}</p>
                    </div>
                </div>

                <div className="npmodal__language_select_grid">
                    {project.projects.map(project => {
                        // Find the language name from code in allLanguages if needed
                        let languageName = project.language_name;
                        if (!languageName && project.language_code) {
                            const language = allLanguages.find(lang => lang.code === project.language_code);
                            languageName = language ? language.name : project.language_code;
                        }

                        // Determine if we're checking video availability for this project
                        const isChecking = checkingVideo[project.id] === true;
                        // Determine if video is available for this project
                        const hasVideo = videoAvailability[project.id] === true;

                        return action === 'edit' ? (
                            // ÉDITION : La carte entière est cliquable
                            <div
                                key={project.id}
                                className="npmodal__language_select_option npmodal__language_select_option_edit"
                                onClick={() => onLanguageSelect(project.id, project.language_code)}
                            >
                                <div className="npmodal__language_select_option_content">
                                    <div className="npmodal__language_select_icon">
                                        <i className="fas fa-language"></i>
                                    </div>
                                    <div className="npmodal__language_select_details">
                                        <span className="npmodal__language_select_name">{languageName || "Langue inconnue"}</span>
                                        <span className="npmodal__language_select_action">Cliquez pour éditer</span>
                                    </div>
                                    <div className="npmodal__language_select_arrow">
                                        <i className="fas fa-chevron-right"></i>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            // TÉLÉCHARGEMENT : Boutons spécifiques pour audio/vidéo
                            <div key={project.id} className="npmodal__language_select_option">
                                <div className="npmodal__language_select_option_content">
                                    <div className="npmodal__language_select_icon">
                                        <i className="fas fa-language"></i>
                                    </div>
                                    <div className="npmodal__language_select_details">
                                        <span className="npmodal__language_select_name">{languageName || "Langue inconnue"}</span>

                                        <div className="npmodal__language_download_actions">
                                            {/* Bouton Audio */}
                                            <button
                                                className="npmodal__download_btn npmodal__download_btn_audio"
                                                onClick={() => handleExport(project.id, project.language_code, 'audio')}
                                                disabled={exporting !== null}
                                            >
                                                {exporting?.projectId === project.id && exporting?.type === 'audio' ? (
                                                    <i className="fas fa-spinner fa-spin"></i>
                                                ) : (
                                                    <i className="fas fa-music"></i>
                                                )}
                                                <span>
                                                    {exporting?.projectId === project.id && exporting?.type === 'audio'
                                                        ? "Téléchargement..."
                                                        : "Audio"}
                                                </span>
                                            </button>

                                            {/* Bouton Vidéo */}
                                            <button
                                                className="npmodal__download_btn npmodal__download_btn_video"
                                                onClick={() => handleExport(project.id, project.language_code, 'video')}
                                                disabled={exporting !== null || isChecking || !hasVideo}
                                            >
                                                {exporting?.projectId === project.id && exporting?.type === 'video' ? (
                                                    <i className="fas fa-spinner fa-spin"></i>
                                                ) : isChecking ? (
                                                    <i className="fas fa-spinner fa-spin"></i>
                                                ) : !hasVideo ? (
                                                    <i className="fas fa-ban"></i>
                                                ) : (
                                                    <i className="fas fa-film"></i>
                                                )}
                                                <span>
                                                    {exporting?.projectId === project.id && exporting?.type === 'video'
                                                        ? "Téléchargement..."
                                                        : isChecking
                                                            ? "Vérification..."
                                                            : !hasVideo
                                                                ? "Vidéo non disponible"
                                                                : "Vidéo"}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

// Main Component: NewProjectModalV2
const NewProjectModalV2 = ({ isOpen, onClose, onProjectCreated, allLanguages, userCoins, onCoinsUpdated, userPlan = 'free' }) => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        files: [],
        languages: []
    });
    const [estimatedDuration, setEstimatedDuration] = useState(0);
    const [estimatedTokens, setEstimatedTokens] = useState(0);
    const [projectStatus, setProjectStatus] = useState({
        status: 'idle', // idle, loading, completed, failed
        groupId: null,
        result: null // Store the full API response
    });

    // New states for language selection modals
    const [showEditLanguageModal, setShowEditLanguageModal] = useState(false);
    const [showDownloadLanguageModal, setShowDownloadLanguageModal] = useState(false);
    const [projectDetails, setProjectDetails] = useState(null);

    const getMaxFileSize = () => {
        const planBase = userPlan ? userPlan.split('-')[0] : 'default';
        return FILE_SIZE_LIMITS[planBase] || FILE_SIZE_LIMITS.default;
    };

    const maxFileSize = getMaxFileSize();

    // Effect pour mettre à jour l'estimation des tokens lorsque les fichiers ou les langues changent
    useEffect(() => {
        const updateEstimation = async () => {
            if (formData.files.length > 0) {
                try {
                    const duration = await estimateAudioDuration(formData.files);
                    setEstimatedDuration(duration);

                    // Mettre à jour l'estimation des tokens basée sur la durée et les langues sélectionnées
                    const tokens = estimateRequiredTokens(duration, formData.languages.length || 1);
                    setEstimatedTokens(tokens);
                } catch (error) {
                    console.error('Erreur lors de l\'estimation de la durée:', error);
                }
            } else {
                setEstimatedDuration(0);
                setEstimatedTokens(0);
            }
        };

        updateEstimation();
    }, [formData.files, formData.languages]);

    const resetForm = () => {
        setCurrentStep(1);
        setFormData({
            name: '',
            files: [],
            languages: []
        });
        setEstimatedDuration(0);
        setEstimatedTokens(0);
        setError(null);
        setProjectStatus({
            status: 'idle',
            groupId: null,
            result: null
        });
    };

    const handleClose = () => {
        // Just close the modal without resetting state
        onClose();
    };

    // New function to start a new project
    const handleNewProject = () => {
        resetForm();
    };

    // Fetch project details when needed for language selection
    // Use the project data we already have after creation, without additional API calls
    const getProjectDetails = (groupId) => {
        // When a project is created, we already receive language details in the response
        // So for newly created projects, we can build the details object from the form data
        const details = {
            group_id: groupId,
            project_name: formData.name,
            status: "completed",
            projects: []
        };

        // For each language code in the form, create a project entry
        // Note: We don't have actual project IDs yet, but we'll handle that below
        formData.languages.forEach((langCode, index) => {
            // Find the language name from allLanguages
            const language = allLanguages.find(lang => lang.code === langCode);
            if (language) {
                details.projects.push({
                    id: `temp_${index}`, // Temporary ID
                    language_code: langCode,
                    language_name: language.name
                });
            } else {
                // Fallback if we can't find the language
                details.projects.push({
                    id: `temp_${index}`, // Temporary ID
                    language_code: langCode,
                    language_name: langCode // Use the code as the name as fallback
                });
            }
        });

        return details;
    };

    const handleDownload = async () => {
        try {
            // If there's only one language, we need the first project from the group
            if (formData.languages.length === 1) {
                // We need to find the actual project ID for this language
                // For newly created projects, we can use the existing response data
                if (projectStatus.result && projectStatus.result.projects && projectStatus.result.projects.length > 0) {
                    // The result should have actual project IDs
                    const projectId = projectStatus.result.projects[0].id;
                    const blob = await projectService.exportAudio(projectId);
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `project_${projectId}.wav`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                } else {
                    // If no result details, we need to query the API to get all projects
                    try {
                        // Get all projects to find our group
                        const allProjects = await projectService.getAllProjects();
                        const group = allProjects.find(g => g.group_id === projectStatus.groupId);

                        if (group && group.projects && group.projects.length > 0) {
                            const projectId = group.projects[0].id;
                            const blob = await projectService.exportAudio(projectId);
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = `project_${projectId}.wav`;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            document.body.removeChild(a);
                        } else {
                            throw new Error("Impossible de trouver le projet");
                        }
                    } catch (error) {
                        console.error('Erreur lors de la récupération des détails du projet:', error);
                        setError("Erreur lors du téléchargement du fichier");
                    }
                }
            } else {
                // Show language selection modal if multiple languages
                // First, check if we have project details from the creation response
                if (projectStatus.result && projectStatus.result.projects) {
                    // Map the result projects to include language names from allLanguages
                    const projectsWithNames = projectStatus.result.projects.map(project => {
                        let languageName = project.language_name;
                        if (!languageName && project.language_code) {
                            const language = allLanguages.find(lang => lang.code === project.language_code);
                            languageName = language ? language.name : project.language_code;
                        }
                        return {
                            ...project,
                            language_name: languageName
                        };
                    });

                    setProjectDetails({
                        group_id: projectStatus.groupId,
                        project_name: formData.name,
                        projects: projectsWithNames
                    });
                    setShowDownloadLanguageModal(true);
                } else {
                    // If no result details, we need to query the API to get all projects
                    try {
                        // Get all projects to find our group
                        const allProjects = await projectService.getAllProjects();
                        const group = allProjects.find(g => g.group_id === projectStatus.groupId);

                        if (group) {
                            setProjectDetails(group);
                            setShowDownloadLanguageModal(true);
                        } else {
                            throw new Error("Impossible de trouver le projet");
                        }
                    } catch (error) {
                        console.error('Erreur lors de la récupération des détails du projet:', error);
                        setError("Erreur lors de la récupération des détails du projet");
                    }
                }
            }
        } catch (error) {
            console.error('Erreur lors du téléchargement:', error);
            setError("Erreur lors du téléchargement du fichier");
        }
    };

    const handleDownloadForLanguage = async (projectId, languageCode, exportType = 'audio') => {
        try {
            const blob = await projectService.exportAudio(projectId, exportType);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;

            // File extension based on export type
            const extension = exportType === 'video' ? 'mp4' : 'wav';
            a.download = `project_${projectId}_${languageCode || 'export'}.${extension}`;

            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            setShowDownloadLanguageModal(false);
        } catch (error) {
            console.error(`Erreur lors du téléchargement ${exportType}:`, error);
            setError(`Erreur lors du téléchargement du ${exportType === 'video' ? 'vidéo' : 'fichier audio'}`);
        }
    };

    const handleEdit = async () => {
        try {
            // If there's only one language, navigate directly
            if (formData.languages.length === 1) {
                // For newly created projects, we should have the project ID in the result
                if (projectStatus.result && projectStatus.result.projects && projectStatus.result.projects.length > 0) {
                    const projectId = projectStatus.result.projects[0].id;
                    navigate(`/main/${projectId}`);
                    onClose();
                } else {
                    // If no result details, we need to query the API to get all projects
                    try {
                        // Get all projects to find our group
                        const allProjects = await projectService.getAllProjects();
                        const group = allProjects.find(g => g.group_id === projectStatus.groupId);

                        if (group && group.projects && group.projects.length > 0) {
                            const projectId = group.projects[0].id;
                            navigate(`/main/${projectId}`);
                            onClose();
                        } else {
                            throw new Error("Impossible de trouver le projet");
                        }
                    } catch (error) {
                        console.error('Erreur lors de la récupération des détails du projet:', error);
                        setError("Erreur lors de l'édition du projet");
                    }
                }
            } else {
                // Show language selection modal if multiple languages
                // First, check if we have project details from the creation response
                if (projectStatus.result && projectStatus.result.projects) {
                    // Map the result projects to include language names from allLanguages
                    const projectsWithNames = projectStatus.result.projects.map(project => {
                        let languageName = project.language_name;
                        if (!languageName && project.language_code) {
                            const language = allLanguages.find(lang => lang.code === project.language_code);
                            languageName = language ? language.name : project.language_code;
                        }
                        return {
                            ...project,
                            language_name: languageName
                        };
                    });

                    setProjectDetails({
                        group_id: projectStatus.groupId,
                        project_name: formData.name,
                        projects: projectsWithNames
                    });
                    setShowEditLanguageModal(true);
                } else {
                    // If no result details, we need to query the API to get all projects
                    try {
                        // Get all projects to find our group
                        const allProjects = await projectService.getAllProjects();
                        const group = allProjects.find(g => g.group_id === projectStatus.groupId);

                        if (group) {
                            setProjectDetails(group);
                            setShowEditLanguageModal(true);
                        } else {
                            throw new Error("Impossible de trouver le projet");
                        }
                    } catch (error) {
                        console.error('Erreur lors de la récupération des détails du projet:', error);
                        setError("Erreur lors de la récupération des détails du projet");
                    }
                }
            }
        } catch (error) {
            console.error('Erreur lors de l\'édition:', error);
            setError("Erreur lors de l'édition du projet");
        }
    };

    const handleEditForLanguage = (projectId) => {
        navigate(`/main/${projectId}`);
        setShowEditLanguageModal(false);
        onClose();
    };

    const handleSubmit = async () => {
        setError(null);
        setIsSubmitting(true);
        setProjectStatus({ status: 'loading', groupId: null });

        try {
            const nameError = validateProjectName(formData.name);
            const filesError = validateFiles(formData.files, maxFileSize, userPlan);
            const languagesError = validateLanguages(formData.languages);

            if (nameError || filesError || languagesError) {
                setError(nameError || filesError || languagesError);
                setIsSubmitting(false);
                setProjectStatus(prev => ({ ...prev, status: 'idle' }));
                return;
            }

            // Vérifier si l'utilisateur a suffisamment de tokens
            if (userCoins < estimatedTokens) {
                setError(`Vous n'avez pas assez de tokens pour ce projet. Il vous manque ${estimatedTokens - userCoins} tokens.`);
                setIsSubmitting(false);
                setProjectStatus(prev => ({ ...prev, status: 'idle' }));
                return;
            }

            const result = await projectService.createProject({
                name: formData.name,
                files: formData.files,
                languages: formData.languages
            });

            console.log("Projet créé avec succès:", result);

            // Si la création a réussi
            if (result && result.group_id) {
                setProjectStatus({
                    status: 'completed',
                    groupId: result.group_id,
                    result: result // Store the full API response including project details
                });

                // IMPORTANT: Programmer la mise à jour de la grille et des tokens après 4 secondes
                console.log("Configuration du timer pour actualisation après 4 secondes");
                setTimeout(() => {
                    console.log("Timer déclenché après 4s - mise à jour de la grille et des tokens");

                    // Actualiser la grille de projets
                    if (typeof onProjectCreated === 'function') {
                        console.log("Appel de onProjectCreated pour actualiser la grille");
                        onProjectCreated();
                    }

                    // Récupérer à nouveau les tokens de l'utilisateur
                    updateUserCoins();
                }, 4000);
            } else {
                throw new Error('Une erreur est survenue lors de la création du projet');
            }

        } catch (err) {
            console.error("Erreur lors de la création du projet:", err);
            setError(err.message || 'Une erreur est survenue lors de la création du projet');
            setProjectStatus({ status: 'failed', groupId: null });
        } finally {
            setIsSubmitting(false);
        }
    };

    const updateUserCoins = async () => {
        try {
            console.log("Mise à jour des tokens utilisateur");
            const coinsData = await FingerprintService.getUserCoins();
            console.log("Nouveaux tokens récupérés:", coinsData.coins);

            // Mettre à jour les tokens dans le composant parent
            if (typeof onCoinsUpdated === 'function') {
                console.log("Mise à jour des tokens dans le composant parent");
                onCoinsUpdated(coinsData.coins);
            }
        } catch (error) {
            console.error('Erreur lors de la mise à jour des tokens:', error);
        }
    };

    // Remplacez cette fonction dans le composant NewProjectModalV2
    const handleNext = () => {
        setError(null);
        let validationError = null;

        switch (currentStep) {
            case 1:
                validationError = validateProjectName(formData.name);
                break;
            case 2:
                validationError = validateFiles(formData.files, maxFileSize, userPlan);
                break;
            case 3:
                validationError = validateLanguages(formData.languages);
                // Vérifier si l'utilisateur a suffisamment de tokens (uniquement à l'étape 3)
                if (!validationError && userCoins < estimatedTokens) {
                    validationError = `Vous n'avez pas assez de tokens pour ce projet. Il vous manque ${estimatedTokens - userCoins} tokens.`;
                }
                break;
        }

        if (validationError) {
            setError(validationError);
            return;
        }

        if (currentStep < 3) {
            setCurrentStep(prev => prev + 1);
        } else {
            handleSubmit();
        }
    };

    const renderProcessingStep = () => {
        switch (projectStatus.status) {
            case 'loading':
                return (
                    <div className="processing-container">
                        <div className="processing-animation">
                            <i className="fas fa-spinner fa-spin fa-3x" />
                        </div>
                        <h3>Doublage de l'audio en cours...</h3>
                        <p>Veuillez patienter pendant la création de votre projet</p>
                    </div>
                );

            case 'completed':
                return (
                    <div className="completion-container">
                        <div className="success-icon">
                            <i className="fas fa-check-circle fa-3x text-green-500" />
                        </div>
                        <h3>Création terminée avec succès !</h3>
                        <div className="modal-actions">
                            <button
                                className="btn-secondary"
                                onClick={handleNewProject}
                            >
                                Nouveau projet
                            </button>
                            <button
                                className="btn-primary"
                                onClick={handleDownload}
                            >
                                Télécharger
                            </button>
                            <button
                                className="btn-next"
                                onClick={handleEdit}
                            >
                                Éditer
                            </button>
                        </div>
                    </div>
                );

            case 'failed':
                return (
                    <div className="error-container">
                        <div className="error-icon">
                            <i className="fas fa-exclamation-circle fa-3x text-red-500" />
                        </div>
                        <h3>La création a échoué</h3>
                        <p>{error || "Une erreur s'est produite lors de la création"}</p>
                        <div className="modal-actions">
                            <button
                                className="btn-primary"
                                onClick={handleNewProject}
                            >
                                Nouveau projet
                            </button>
                            <button
                                className="btn-secondary"
                                onClick={() => {
                                    setProjectStatus({ status: 'idle', groupId: null });
                                    setError(null);
                                }}
                            >
                                Réessayer
                            </button>
                        </div>
                    </div>
                );

            default:
                return null;
        }
    };

    // Modification à apporter à la section modalActionsWithTokens
    const modalActionsWithTokens = (
        <>
            <div className="modal-actions">
                <div className="modal-actions-buttons">
                    <TokenDisplay estimatedTokens={estimatedTokens} userCoins={userCoins} />

                    {currentStep > 1 && (
                        <button
                            type="button"
                            className="btn-prev"
                            onClick={() => setCurrentStep(prev => prev - 1)}
                            disabled={isSubmitting}
                        >
                            Retour
                        </button>
                    )}
                    <button
                        type="button"
                        className={`btn-next ${currentStep === 3 && userCoins < estimatedTokens ? 'btn-disabled' : ''}`}
                        onClick={(e) => {
                            if (currentStep === 3 && userCoins < estimatedTokens) {
                                // Animation pour tokens insuffisants
                                const tokenElement = document.querySelector('.tokens-dashboard');
                                if (tokenElement) {
                                    tokenElement.classList.remove('shake-once');
                                    void tokenElement.offsetWidth; // Force reflow
                                    tokenElement.classList.add('shake-once');
                                }
                                // Afficher un message d'erreur
                                setError(`Vous n'avez pas assez de tokens pour ce projet. Il vous manque ${estimatedTokens - userCoins} tokens.`);
                            } else {
                                handleNext();
                            }
                        }}
                        disabled={isSubmitting || (currentStep === 3 && userCoins < estimatedTokens)}
                    >
                        {currentStep === 3 ? 'Lancer le doublage' : 'Suivant'}
                    </button>
                </div>
            </div>
        </>
    );

    return (
        <div className={`modal ${isOpen ? 'active' : ''}`}>
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">Nouveau projet</h2>
                    <button
                        className="close-modal"
                        onClick={handleClose}
                        aria-label="Fermer"
                    >
                        <i className="fas fa-times" />
                    </button>
                </div>

                {error && projectStatus.status === 'idle' && (
                    <div className="notification error">
                        <i className="fas fa-exclamation-circle" />
                        {error}
                    </div>
                )}

                {projectStatus.status === 'idle' ? (
                    <>
                        <div className="modal-steps">
                            <Step
                                number={1}
                                label="Informations"
                                isActive={currentStep === 1}
                                isCompleted={currentStep > 1}
                            />
                            <Step
                                number={2}
                                label="Fichiers"
                                isActive={currentStep === 2}
                                isCompleted={currentStep > 2}
                            />
                            <Step
                                number={3}
                                label="Langues"
                                isActive={currentStep === 3}
                                isCompleted={currentStep > 3}
                            />
                        </div>

                        <div className="modal-body">
                            <StepContent
                                step={currentStep}
                                formData={formData}
                                setFormData={setFormData}
                                onRemoveFile={(index) => {
                                    setFormData(prev => ({
                                        ...prev,
                                        files: prev.files.filter((_, i) => i !== index)
                                    }));
                                }}
                                allLanguages={allLanguages}
                                setError={setError}
                                maxFileSize={maxFileSize} // Utiliser la limite dynamique
                                estimatedTokens={estimatedTokens}
                                userCoins={userCoins}
                                userPlan={userPlan} // Passer le plan utilisateur
                            />
                        </div>

                        {modalActionsWithTokens}
                    </>
                ) : (
                    <div className="modal-body">
                        {renderProcessingStep()}
                    </div>
                )}
            </div>

            {/* Language Selection Modal for Download */}
            <LanguageSelectionModal
                project={projectDetails}
                isOpen={showDownloadLanguageModal}
                onClose={() => setShowDownloadLanguageModal(false)}
                onLanguageSelect={handleDownloadForLanguage}
                action="download"
                allLanguages={allLanguages}
            />

            {/* Language Selection Modal for Edit */}
            <LanguageSelectionModal
                project={projectDetails}
                isOpen={showEditLanguageModal}
                onClose={() => setShowEditLanguageModal(false)}
                onLanguageSelect={handleEditForLanguage}
                action="edit"
                allLanguages={allLanguages}
            />
        </div>
    );
};

export default NewProjectModalV2;