import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Slider, IconButton, Tooltip } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

const PRIMARY_COLOR = '#6366f1';
const SECONDARY_COLOR = '#4338ca';

const AudioVolumeWidget = ({
    selectedSegment,
    onVolumeChange,
    onVolumeChangeCommitted,
    transcript,
    onTranscriptUpdate,
    onSave,
    audioPlayerRefs
}) => {
    const [volume, setVolume] = useState(1.0);
    const [isMuted, setIsMuted] = useState(false);
    const [previousVolume, setPreviousVolume] = useState(1.0);
    const [isHovering, setIsHovering] = useState(false);

    // Initialize volume when a new segment is selected
    useEffect(() => {
        if (selectedSegment) {
            const segmentVolume = selectedSegment.audio_gain !== undefined
                ? selectedSegment.audio_gain
                : 1.0;
            setVolume(segmentVolume);
            setIsMuted(segmentVolume === 0);
        }
    }, [selectedSegment]);

    // Fonction améliorée pour mettre à jour le volume directement
    const updateDirectAudioVolume = useCallback((newValue) => {
        if (!selectedSegment || !audioPlayerRefs?.current) return false;

        try {
            // Récupérer l'AudioPlayer pour ce speaker
            const playerRef = audioPlayerRefs.current[selectedSegment.speaker];

            if (playerRef) {
                // Récupérer le segment actuellement joué
                const currentPlayingSegment = playerRef.getCurrentSegment?.();

                // Vérifier si c'est le segment sélectionné qui est en cours de lecture
                if (currentPlayingSegment &&
                    currentPlayingSegment.segment_file === selectedSegment.segment_file) {

                    console.log(`Applying volume ${newValue} directly to playing segment: ${selectedSegment.segment_file}`);

                    // Créer une copie du segment avec le nouveau volume
                    const updatedSegment = {
                        ...currentPlayingSegment,
                        audio_gain: newValue
                    };

                    // Appliquer directement le volume
                    return playerRef.updateVolume(updatedSegment);
                }
            }
        } catch (error) {
            console.error("Error updating direct audio volume:", error);
        }

        return false;
    }, [selectedSegment, audioPlayerRefs]);

    const handleVolumeChange = useCallback((_, newValue) => {
        setVolume(newValue);
        setIsMuted(newValue === 0);

        if (selectedSegment) {
            // Mettre à jour le transcript
            const updatedTranscript = transcript.map(segment => {
                if (
                    segment.start === selectedSegment.start &&
                    segment.speaker === selectedSegment.speaker &&
                    segment.segment_file === selectedSegment.segment_file
                ) {
                    return { ...segment, audio_gain: newValue };
                }
                return segment;
            });

            // Appliquer la mise à jour du transcript
            onTranscriptUpdate(updatedTranscript);

            // Essayer d'appliquer directement le volume à l'audio en cours
            updateDirectAudioVolume(newValue);

            // Appeler aussi le handler externe si fourni
            if (onVolumeChange) {
                onVolumeChange(newValue, selectedSegment);
            }
        }
    }, [selectedSegment, transcript, onTranscriptUpdate, onVolumeChange, updateDirectAudioVolume]);

    const handleVolumeChangeCommitted = useCallback(() => {
        if (selectedSegment && onVolumeChangeCommitted) {
            onVolumeChangeCommitted(volume, selectedSegment);
        }

        // Sauvegarder le changement sur le serveur
        if (selectedSegment && onSave) {
            onSave();
        }
    }, [selectedSegment, volume, onVolumeChangeCommitted, onSave]);

    const toggleMute = useCallback(() => {
        if (isMuted) {
            // Unmute: restore previous volume
            const newVolume = previousVolume > 0 ? previousVolume : 0.5;
            setVolume(newVolume);
            setIsMuted(false);

            if (selectedSegment) {
                const updatedTranscript = transcript.map(segment => {
                    if (
                        segment.start === selectedSegment.start &&
                        segment.speaker === selectedSegment.speaker &&
                        segment.segment_file === selectedSegment.segment_file
                    ) {
                        return { ...segment, audio_gain: newVolume };
                    }
                    return segment;
                });

                onTranscriptUpdate(updatedTranscript);
                updateDirectAudioVolume(newVolume);
                if (onSave) onSave();
            }
        } else {
            // Mute: save current volume and set to 0
            setPreviousVolume(volume);
            setVolume(0);
            setIsMuted(true);

            if (selectedSegment) {
                const updatedTranscript = transcript.map(segment => {
                    if (
                        segment.start === selectedSegment.start &&
                        segment.speaker === selectedSegment.speaker &&
                        segment.segment_file === selectedSegment.segment_file
                    ) {
                        return { ...segment, audio_gain: 0 };
                    }
                    return segment;
                });

                onTranscriptUpdate(updatedTranscript);
                updateDirectAudioVolume(0);
                if (onSave) onSave();
            }
        }
    }, [isMuted, previousVolume, volume, selectedSegment, transcript, onTranscriptUpdate, onSave, updateDirectAudioVolume]);

    // Don't render if no segment is selected
    if (!selectedSegment) {
        return null;
    }

    // Définir la couleur du volume en fonction de sa valeur
    const getVolumeColor = () => {
        if (isMuted) return '#888';
        if (volume > 0.75) return PRIMARY_COLOR;
        if (volume > 0.4) return '#8c92fa';
        return '#a7abff';
    };

    // Définir l'icône et le message du tooltip en fonction du volume
    const getVolumeIcon = () => {
        if (isMuted) {
            return <VolumeOffIcon fontSize="small" />;
        } else if (volume < 0.3) {
            return <VolumeDownIcon fontSize="small" />;
        } else {
            return <VolumeUpIcon fontSize="small" />;
        }
    };

    const getVolumeTooltip = () => {
        if (isMuted) return "Unmute";
        return "Mute";
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                position: 'relative',
                py: 0.5,
            }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <Tooltip title={getVolumeTooltip()}>
                <IconButton
                    size="small"
                    onClick={toggleMute}
                    sx={{
                        color: isMuted ? 'rgba(255,255,255,0.4)' : getVolumeColor(),
                        transition: 'color 0.2s ease',
                        '&:hover': {
                            color: isMuted ? 'rgba(255,255,255,0.7)' : PRIMARY_COLOR,
                            backgroundColor: 'rgba(99, 102, 241, 0.08)',
                        }
                    }}
                >
                    {getVolumeIcon()}
                </IconButton>
            </Tooltip>

            <Box
                sx={{
                    mx: 2,
                    width: '100%',
                    position: 'relative',
                }}
            >
                <Slider
                    value={volume}
                    onChange={handleVolumeChange}
                    onChangeCommitted={handleVolumeChangeCommitted}
                    min={0}
                    max={1}
                    step={0.01}
                    sx={{
                        width: '100%',
                        color: getVolumeColor(),
                        height: 4,
                        transition: 'color 0.2s ease',
                        '& .MuiSlider-thumb': {
                            width: 14,
                            height: 14,
                            transition: '0.2s ease-in-out',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
                            '&:hover, &.Mui-focusVisible': {
                                boxShadow: `0 0 0 8px ${isHovering ? 'rgba(99, 102, 241, 0.16)' : 'rgba(99, 102, 241, 0.08)'}`,
                            },
                            '&:before': {
                                boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                            },
                        },
                        '& .MuiSlider-rail': {
                            opacity: 0.3,
                        },
                        '& .MuiSlider-track': {
                            height: 4,
                            border: 'none',
                        },
                        '&:hover': {
                            '& .MuiSlider-thumb': {
                                boxShadow: '0 0 0 6px rgba(99, 102, 241, 0.16)',
                            },
                        },
                    }}
                />
            </Box>

            <Typography
                variant="caption"
                sx={{
                    color: getVolumeColor(),
                    width: '38px',
                    textAlign: 'right',
                    fontWeight: 600,
                    fontSize: '0.75rem',
                    transition: 'color 0.2s ease',
                }}
            >
                {Math.round(volume * 100)}%
            </Typography>
        </Box>
    );
};

export default AudioVolumeWidget;