import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage/LoginPage.jsx';
import LandingPage from './pages/LandingPage/LandingPage.jsx';
import FeaturesPage from './pages/FeaturesPage/FeaturesPage.jsx';
import DemoPage from './pages/DemoPage/DemoPage.jsx';
import PricingPage from './pages/PricingPage/PricingPage.jsx';
import DashboardPage from './pages/DashboardPage/DashboadPage.jsx';
import SettingsPage from './pages/SettingPage/SettingsPage.jsx';
import MainPage from './pages/EditingSoftware/MainPage.js';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy.jsx';
import TermsOfService from './pages/TermsOfService/TermsOfService.jsx';
import CookiePolicy from './pages/CookiePolicy/CookiePolicy.jsx';
import LegalNotice from './pages/LegalNotice/LegalNotice.jsx';
import RegisterPage from './pages/RegisterPage/RegisterPage.jsx';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import SuccessPage from './pages/SuccessPage/SuccessPage.jsx';
import { Navigate } from 'react-router-dom';
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* Routes publiques */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/demo" element={<DemoPage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/cookies" element={<CookiePolicy />} />
          <Route path="/legal" element={<LegalNotice />} />


          <Route path="/success" element={<SuccessPage />} />
          <Route path="/cancel" element={<Navigate to="/pricing" />} />
          <Route path="/pricing" element={<PricingPage />} />

          <Route path="/dashboard" element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          } />
          <Route path="/settings" element={
            <PrivateRoute>
              <SettingsPage />
            </PrivateRoute>
          } />
          <Route path="/main/:projectId" element={
            <PrivateRoute>
              <MainPage />
            </PrivateRoute>
          } />
        </Routes>
      </Router>
    </div>
  );
}

export default App;