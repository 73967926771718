import { API_CONFIG } from '../config/constants';
import FingerprintService from './fingerprint.service'; // Changé ici - import par défaut

const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No authentication token found');
    }
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
};

export const projectService = {
    getAllProjects: async () => {
        try {
            const response = await fetch(`${API_CONFIG.BASE_URL}/projects`, {
                method: 'GET',
                headers: getAuthHeader()
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    throw new Error('Session expirée');
                }
                const error = await response.json();
                throw new Error(error.error || 'Échec de la récupération des projets');
            }

            const data = await response.json();
            return data.projects;
        } catch (error) {
            console.error('Error fetching projects:', error);
            if (error.message === 'No authentication token found') {
                window.location.href = '/login';
            }
            throw error;
        }
    },

    getLanguages: async () => {
        try {
            const response = await fetch(`${API_CONFIG.BASE_URL}/languages`, {
                method: 'GET',
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.error || 'Échec de la récupération des langages');
            }

            const data = await response.json();
            return data.languages;
        } catch (error) {
            console.error('Error fetching languages:', error);
            throw error;
        }
    },

    createProject: async (projectData) => {
        try {
            if (!projectData.name) {
                throw new Error('Le nom du projet est requis');
            }

            const fingerprint = await FingerprintService.getFingerprint();
            const token = localStorage.getItem('token');

            const formData = new FormData();
            formData.append('name', projectData.name);
            formData.append('fingerprint', fingerprint);

            // Ajouter chaque langue sélectionnée
            projectData.languages.forEach(lang => {
                formData.append('languages', lang);
            });

            // Ajouter les fichiers
            if (projectData.files) {
                projectData.files.forEach(file => {
                    formData.append('files', file);
                });
            }

            // Préparer les headers
            const headers = {};
            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }

            const response = await fetch(`${API_CONFIG.BASE_URL}/projects`, {
                method: 'POST',
                headers: headers,
                body: formData
            });

            if (!response.ok) {
                if (response.status === 401 && token) {
                    localStorage.removeItem('token');
                }
                const error = await response.json();

                if (response.status === 400 && error.required !== undefined) {
                    throw {
                        type: 'InsufficientCoins',
                        required: error.required,
                        current: error.current,
                        message: 'Solde de coins insuffisant'
                    };
                }

                throw new Error(error.error || 'Échec de la création du projet');
            }

            const responseData = await response.json();
            console.log('Réponse de l\'API createProject:', responseData);

            return {
                ...responseData,
                coinsInfo: {
                    used: responseData.coins_used,
                    remaining: responseData.remaining_coins
                }
            };

        } catch (error) {
            console.error('Error creating project:', error);
            throw error;
        }
    },

    getProjectStatus: async (groupId) => {
        try {
            const response = await fetch(`${API_CONFIG.BASE_URL}/projects/${groupId}/status`, {
                method: 'GET',
                headers: getAuthHeader()
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    throw new Error('Session expirée');
                }
                if (response.status === 404) {
                    throw new Error('Projet non trouvé');
                }
                const error = await response.json();
                throw new Error(error.error || 'Échec de la récupération du statut');
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching project status:', error);
            throw error;
        }
    },

    exportAudio: async (projectId, exportType = 'audio') => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            // Use the appropriate endpoint based on export type
            const endpoint = exportType === 'video'
                ? `${API_CONFIG.BASE_URL}/export_audio_video/${projectId}`
                : `${API_CONFIG.BASE_URL}/export_audio/${projectId}`;

            const method = exportType === 'video' ? 'POST' : 'GET';
            const body = exportType === 'video' ? JSON.stringify({ export_type: 'video' }) : undefined;
            const contentType = exportType === 'video' ? 'application/json' : undefined;

            const headers = {
                'Authorization': `Bearer ${token}`,
                ...(contentType && { 'Content-Type': contentType })
            };

            console.log(`Exporting ${exportType} for project ${projectId}...`);
            const response = await fetch(endpoint, {
                method,
                headers,
                body
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    throw new Error('Session expirée');
                }
                if (response.status === 404) {
                    throw new Error('Projet non trouvé');
                }

                // Check if response is JSON
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.includes('application/json')) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || `Échec de l'export ${exportType === 'video' ? 'vidéo' : 'audio'}`);
                } else {
                    // Handle non-JSON error response
                    const errorText = await response.text();
                    console.error('Server error response:', errorText);
                    throw new Error(`Échec de l'export ${exportType === 'video' ? 'vidéo' : 'audio'}`);
                }
            }

            // Handle the blob response directly
            return await response.blob();
        } catch (error) {
            console.error(`Error exporting ${exportType}:`, error);

            // Provide more detailed error information
            if (error.message && error.message.includes("'dict' object has no attribute 'sort'")) {
                throw new Error(`Erreur serveur pendant l'export. L'équipe technique a été notifiée. Veuillez réessayer plus tard.`);
            }

            if (error.message === 'No authentication token found') {
                window.location.href = '/login';
            }

            throw error;
        }
    },

    // Check if video is available for a project
    checkVideoAvailability: async (projectId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(`${API_CONFIG.BASE_URL}/get_file/${projectId}/video.mp4`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                if (response.status === 404) {
                    // Video not found - this is a normal scenario
                    return { available: false };
                }

                if (response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    throw new Error('Session expirée');
                }

                const error = await response.json();
                throw new Error(error.error || "Échec de la vérification vidéo");
            }

            // If we get here, the video is available
            const data = await response.json();
            return {
                available: true,
                url: data.url
            };

        } catch (error) {
            // If it's a 404, just indicate video isn't available
            if (error.message && error.message.includes('404')) {
                return { available: false };
            }

            console.error('Error checking video availability:', error);
            if (error.message === 'No authentication token found') {
                window.location.href = '/login';
            }

            // For other errors, rethrow
            throw error;
        }
    },

    // Get a specific project group by ID (can be used to get language options)
    getProjectGroup: async (groupId) => {
        try {
            // Since there's no direct endpoint for this in your API,
            // we'll get all projects and filter by group ID
            const allProjects = await projectService.getAllProjects();
            const group = allProjects.find(g => g.group_id === groupId);

            if (!group) {
                throw new Error('Groupe de projets non trouvé');
            }

            return group;
        } catch (error) {
            console.error('Error fetching project group:', error);
            throw error;
        }
    },

    deleteProject: async (groupId) => {
        try {
            const response = await fetch(`${API_CONFIG.BASE_URL}/projects/${groupId}`, {
                method: 'DELETE',
                headers: getAuthHeader()
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    throw new Error('Session expirée');
                }
                if (response.status === 404) {
                    throw new Error('Groupe de projets non trouvé');
                }
                const error = await response.json();
                throw new Error(error.error || 'Échec de la suppression du groupe de projets');
            }

            return await response.json();
        } catch (error) {
            console.error('Error deleting project group:', error);
            throw error;
        }
    },

};