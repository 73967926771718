// src/components/PrivateRoute/PrivateRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { authService } from '../../services/auth.service';

const PrivateRoute = ({ children }) => {
    const isAuthenticated = authService.isAuthenticated();
    const currentPath = window.location.pathname;

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: currentPath }} />;
    }
    return children;
};

export default PrivateRoute;