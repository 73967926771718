import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Box, Slider, IconButton, Typography, Tooltip } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import TranslateIcon from '@mui/icons-material/Translate';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import AddIcon from '@mui/icons-material/Add';

import DeleteIcon from '@mui/icons-material/Delete';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import WaveformDisplay from './WaveForm';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { API_CONFIG } from '../../config/constants';
import RemoveIcon from '@mui/icons-material/Remove';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AudioVolumeWidget from './AudioVolumeWidget';

const TRACK_HEIGHT = 40;
const TIMELINE_PADDING = 10;
const CURSOR_WIDTH = 2;
const SPEAKER_LABEL_WIDTH = 200;
const TIMESCALE_HEIGHT = 22;

const PRIMARY_COLOR = '#6366f1';
const SECONDARY_COLOR = '#4f46e5';

function Timeline({ currentTime,
    duration,
    onSeek,
    transcript,
    selectedBlock,
    onBlockSelect,
    onTranscriptUpdate,
    mutedTracks,
    toggleMute,
    projectId,
    onSave,
    isVideoAudioEnabled,
    onResetAudioCache,
    handleAudioReload,
    toggleVideoAudio,
    hasVideo,
    isPlaying }) {
    const containerRef = useRef(null);
    const timelineRef = useRef(null);
    const [zoom, setZoom] = useState(2);
    const [scrollPercentage, setScrollPercentage] = useState(0);
    const [hoveredBlock, setHoveredBlock] = useState(null);
    const [draggingBlock, setDraggingBlock] = useState(null);
    const [dragStartX, setDragStartX] = useState(0);
    const [dragStartTime, setDragStartTime] = useState(0);
    const [isCutMode, setIsCutMode] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(currentTime);
    const [speakerOrder, setSpeakerOrder] = useState([]);
    const [showTranslatedText, setShowTranslatedText] = useState(true);
    const [selectedBlocks, setSelectedBlocks] = useState([]);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const hasMovedRef = useRef(false);
    const waveformRefs = useRef({});
    const [maxSpeaker, setMaxSpeaker] = useState(0);
    // Ajouter un nouvel état pour tracker les speakers ajoutés manuellement
    const [manualSpeakers, setManualSpeakers] = useState([]);
    const [backgroundAudio, setBackgroundAudio] = useState({});
    const [isCutProcessing, setIsCutProcessing] = useState(false);


    // Fonction pour la gestion de la suppression d'une piste
    const removeSpeaker = async (speaker) => {
        // Vérifier qu'on garde au moins une piste
        if (speakerOrder.length <= 1) {
            alert("Impossible de supprimer la dernière piste.");
            return;
        }

        // Trouver la piste précédente
        const speakerIndex = speakerOrder.indexOf(speaker);
        const previousSpeaker = speakerIndex > 0
            ? speakerOrder[speakerIndex - 1]
            : speakerOrder[1]; // Si c'est le premier, prendre le suivant

        // Vérifier si le speaker a des segments
        const hasSpeakerSegments = transcript.some(segment => segment.speaker === speaker);

        if (hasSpeakerSegments) {
            // Transférer automatiquement les segments vers la piste précédente
            const updatedTranscript = transcript.map(segment => {
                if (segment.speaker === speaker) {
                    return { ...segment, speaker: previousSpeaker };
                }
                return segment;
            });

            // Mettre à jour le transcript
            onTranscriptUpdate(updatedTranscript);
            await onSave(updatedTranscript);
        }

        // Supprimer le speaker
        setManualSpeakers(prev => prev.filter(s => s !== speaker));
        setSpeakerOrder(prev => prev.filter(s => s !== speaker));
    };

    const handleAddSpeaker = () => {
        // Trouver le numéro maximal actuel parmi les pistes existantes
        const usedNumbers = speakerOrder
            .filter(s => s.startsWith('SPEAKER')) // Ne considérer que les SPEAKER
            .map(s => parseInt(s.split('_')[1])); // Extraire les numéros

        // Si aucun SPEAKER n'existe, commencer à 0
        const maxNumber = usedNumbers.length > 0 ? Math.max(...usedNumbers) : -1;

        // Le prochain numéro est maxNumber + 1
        const nextNumber = maxNumber + 1;

        const newSpeaker = `SPEAKER_${nextNumber}`;

        // Ajouter la nouvelle piste à manualSpeakers
        setManualSpeakers(prev => [...prev, newSpeaker]);

        // Mettre à jour speakerOrder en plaçant la nouvelle piste avant les BACKGROUND
        const updatedSpeakerOrder = [
            ...speakerOrder.filter(s => s.startsWith('SPEAKER')),
            newSpeaker,
            ...speakerOrder.filter(s => s.startsWith('BACKGROUND')),
        ];
        setSpeakerOrder(updatedSpeakerOrder);

        // Mettre à jour maxSpeaker
        setMaxSpeaker(nextNumber);
    };

    // Modifier l'effet qui gère les speakers pour maintenir une numérotation séquentielle
    useEffect(() => {
        // Extraire les speakers uniques du transcript
        const transcriptSpeakers = [...new Set(transcript.map(item => item.speaker))];

        // Séparer les speakers normaux et les backgrounds
        const [backgroundSpeakers, normalSpeakers] = transcriptSpeakers.reduce((acc, speaker) => {
            speaker.startsWith('BACKGROUND') ? acc[0].push(speaker) : acc[1].push(speaker);
            return acc;
        }, [[], []]);

        // Trier les speakers normaux par numéro
        const sortedNormal = normalSpeakers.sort((a, b) => {
            const numA = parseInt(a.split('_')[1]);
            const numB = parseInt(b.split('_')[1]);
            return numA - numB;
        });

        // Trier les backgrounds par numéro
        const sortedBackground = backgroundSpeakers.sort((a, b) => {
            const numA = parseInt(a.split('_')[1]);
            const numB = parseInt(b.split('_')[1]);
            return numA - numB;
        });

        // Combiner avec les speakers manuels (en évitant les doublons)
        const allSpeakers = [
            ...new Set([
                ...sortedNormal,
                ...manualSpeakers.filter(s => s.startsWith('SPEAKER')), // Ajouter uniquement les SPEAKER manuels
                ...sortedBackground,
            ]),
        ];

        // Mettre à jour maxSpeaker (uniquement pour les SPEAKER)
        const speakerNumbers = allSpeakers
            .filter(s => s.startsWith('SPEAKER'))
            .map(s => parseInt(s.split('_')[1]));
        const maxNum = speakerNumbers.length > 0 ? Math.max(...speakerNumbers) : -1;
        setMaxSpeaker(maxNum);

        // Mettre à jour l'ordre des pistes : SPEAKER en haut, BACKGROUND en bas
        const orderedSpeakers = [
            ...new Set([
                ...sortedNormal,
                ...manualSpeakers.filter(s => s.startsWith('SPEAKER')), // Ajouter uniquement les SPEAKER manuels
                ...sortedBackground,
            ]),
        ];

        // Trier orderedSpeakers pour maintenir l'ordre correct
        const sortedOrderedSpeakers = orderedSpeakers.sort((a, b) => {
            if (a.startsWith('SPEAKER') && b.startsWith('SPEAKER')) {
                const numA = parseInt(a.split('_')[1]);
                const numB = parseInt(b.split('_')[1]);
                return numA - numB; // Trier les SPEAKER par numéro croissant
            } else if (a.startsWith('BACKGROUND') && b.startsWith('BACKGROUND')) {
                const numA = parseInt(a.split('_')[1]);
                const numB = parseInt(b.split('_')[1]);
                return numA - numB; // Trier les BACKGROUND par numéro croissant
            } else if (a.startsWith('SPEAKER')) {
                return -1; // Les SPEAKER viennent avant les BACKGROUND
            } else {
                return 1; // Les BACKGROUND viennent après les SPEAKER
            }
        });

        setSpeakerOrder(sortedOrderedSpeakers);

    }, [transcript, manualSpeakers]);

    const handleTimelineMouseMove = useCallback((event) => {
        if (timelineRef.current) {
            const rect = timelineRef.current.getBoundingClientRect();
            const clickX = event.clientX - rect.left;
            const totalWidth = timelineRef.current.scrollWidth;
            const visibleWidth = rect.width;
            const scrollLeft = timelineRef.current.scrollLeft;

            // Calculer le pourcentage total (en tenant compte du scroll)
            const totalPercent = ((scrollLeft + clickX) / totalWidth);
            const clickTime = totalPercent * duration;

            if (isCutMode || isMouseDown) {
                setCursorPosition(Math.max(0, Math.min(duration, clickTime)));
            }
        }
    }, [isCutMode, isMouseDown, duration]);

    const handleTimelineMouseDown = useCallback((event) => {
        setIsMouseDown(true);
        handleTimelineMouseMove(event);
    }, [handleTimelineMouseMove]);

    const handleTimelineMouseUp = useCallback(() => {
        setIsMouseDown(false);
    }, []);

    const handleTimelineMouseLeave = useCallback(() => {
        if (!isCutMode) {
            setCursorPosition(currentTime);
        }
        setHoveredBlock(null);
    }, [isCutMode, currentTime]);

    useEffect(() => {
        if (!isCutMode) {
            setCursorPosition(currentTime);
        }
    }, [isCutMode, currentTime]);

    useEffect(() => {
        if (timelineRef.current) {
            timelineRef.current.addEventListener('mousemove', handleTimelineMouseMove);
            timelineRef.current.addEventListener('mouseleave', handleTimelineMouseLeave);
            timelineRef.current.addEventListener('mousedown', handleTimelineMouseDown);
            document.addEventListener('mouseup', handleTimelineMouseUp);
        }
        return () => {
            if (timelineRef.current) {
                timelineRef.current.removeEventListener('mousemove', handleTimelineMouseMove);
                timelineRef.current.removeEventListener('mouseleave', handleTimelineMouseLeave);
                timelineRef.current.removeEventListener('mousedown', handleTimelineMouseDown);
            }
            document.removeEventListener('mouseup', handleTimelineMouseUp);
        };
    }, [handleTimelineMouseMove, handleTimelineMouseLeave, handleTimelineMouseDown, handleTimelineMouseUp]);

    const handleMouseDown = useCallback((event, block) => {
        if (!isCutMode) {
            event.stopPropagation();
            setDraggingBlock(block);
            setDragStartX(event.clientX);
            if (block) {
                setDragStartTime(block.start);
            }

            setIsDragging(true);
            hasMovedRef.current = false;
            setIsMouseDown(true);
        }
    }, [isCutMode]);

    const handleMouseMove = useCallback((event) => {
        if (isMouseDown && draggingBlock && timelineRef.current) {
            const deltaX = event.clientX - dragStartX;
            const rect = timelineRef.current.getBoundingClientRect();

            // Calculer la piste sur laquelle on se trouve
            const clickY = event.clientY - rect.top;
            const trackIndex = Math.floor((clickY - TIMELINE_PADDING - TIMESCALE_HEIGHT) / TRACK_HEIGHT);
            const newSpeaker = trackIndex >= 0 && trackIndex < speakerOrder.length ?
                speakerOrder[trackIndex] : draggingBlock.speaker;

            // Mettre à jour hasMovedRef immédiatement si le mouvement dépasse le seuil
            if (Math.abs(deltaX) > 2) {
                hasMovedRef.current = true;
            }

            // Mise à jour de la position si on dépasse le seuil ou change de speaker
            if (Math.abs(deltaX) > 2 || draggingBlock.speaker !== newSpeaker) {
                if (!isDragging) {
                    setIsDragging(true);
                }

                const timelineWidth = rect.width;
                const visibleDuration = duration / zoom;
                const pixelsPerSecond = timelineWidth / visibleDuration;
                const deltaTime = deltaX / pixelsPerSecond;

                const newStart = Math.max(0, Math.min(duration - (draggingBlock.end - draggingBlock.start), dragStartTime + deltaTime));
                const newEnd = newStart + (draggingBlock.end - draggingBlock.start);

                // Vérifie si le speaker change
                const speakerChanged = newSpeaker !== draggingBlock.speaker;

                const updatedTranscript = transcript.map(t => {
                    if (t.text === draggingBlock.text) {
                        // Si c'est le segment qu'on déplace
                        const updatedSegment = {
                            ...t,
                            start: newStart,
                            end: newEnd,
                            speaker: newSpeaker
                        };

                        // Si c'est un changement de speaker, ajouter/mettre à jour original_speaker
                        if (speakerChanged) {
                            // Si original_speaker existe déjà, ne pas l'écraser
                            // sinon, mettre le speaker actuel comme original_speaker
                            if (!updatedSegment.original_speaker) {
                                updatedSegment.original_speaker = draggingBlock.speaker;
                            }
                        }

                        return updatedSegment;
                    }
                    return t;
                });

                onTranscriptUpdate(updatedTranscript);

                // Effectuer un save immédiat uniquement si le speaker a changé
                if (speakerChanged) {
                    onSave(updatedTranscript);

                    // Mise à jour de la référence du draggingBlock pour éviter des saves multiples pour le même changement
                    draggingBlock.speaker = newSpeaker;
                }

                if (event.clientX < rect.left + 20) {
                    timelineRef.current.scrollLeft -= 10;
                } else if (event.clientX > rect.right - 20) {
                    timelineRef.current.scrollLeft += 10;
                }
            }
        }
    }, [draggingBlock, dragStartX, dragStartTime, duration, zoom, transcript, onTranscriptUpdate, speakerOrder]);
    const handleMouseUp = useCallback(() => {
        if (draggingBlock && isDragging && hasMovedRef.current) {
            onSave();
        }
        setDraggingBlock(null);
        setIsDragging(false);
        setIsMouseDown(false); // Ajout de cette ligne
        hasMovedRef.current = false;
    }, [draggingBlock, isDragging, onSave]);

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousedown', handleMouseDown);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('mousedown', handleMouseDown);

        };
    }, [handleMouseMove, handleMouseUp]);

    const handleCutModeToggle = () => {
        setIsCutMode(!isCutMode);
    };


    // Utilitaire pour comparer deux segments
    const areSegmentsEqual = (seg1, seg2) => {
        return seg1.start === seg2.start &&
            seg1.end === seg2.end &&
            seg1.speaker === seg2.speaker &&
            seg1.segment_file === seg2.segment_file;
    };

    const findModifiedSegments = (oldTranscript, newTranscript) => {
        // Trouver spécifiquement les segments affectés par le merge
        const modifiedSegments = [];

        // 1. Trouver les deux segments qui ont été fusionnés (ils n'existeront plus dans newTranscript)
        const removedSegments = oldTranscript.filter(oldSeg =>
            !newTranscript.some(newSeg => newSeg.segment_file === oldSeg.segment_file)
        );

        // 2. Trouver le nouveau segment fusionné (il n'existera pas dans oldTranscript)
        const newSegments = newTranscript.filter(newSeg =>
            !oldTranscript.some(oldSeg => oldSeg.segment_file === newSeg.segment_file)
        );

        // 3. Ajouter uniquement ces segments spécifiques aux segments modifiés
        modifiedSegments.push(...newSegments);

        console.log("Segments fusionnés:", modifiedSegments);
        return modifiedSegments;
    };

    const handleTimelineClick = (event) => {
        if (timelineRef.current) {
            const rect = timelineRef.current.getBoundingClientRect();
            const clickX = event.clientX - rect.left;
            const clickY = event.clientY - rect.top;
            const totalWidth = timelineRef.current.scrollWidth;
            const scrollLeft = timelineRef.current.scrollLeft;

            // Utiliser la même logique de calcul que handleTimelineMouseMove
            const totalPercent = ((scrollLeft + clickX) / totalWidth);
            const clickTime = totalPercent * duration;

            const trackIndex = Math.floor((clickY - TIMELINE_PADDING - TIMESCALE_HEIGHT) / TRACK_HEIGHT);
            const speaker = speakerOrder[trackIndex];

            if (isCutMode && !isCutProcessing) {
                // Ne lancez l'opération de coupe que si aucune coupe n'est déjà en cours
                performCut(clickTime, speaker);
            } else if (!isCutMode) {
                const clickedBlock = transcript
                    .filter(t => t.speaker === speaker)
                    .find(t => clickTime >= t.start && clickTime < t.end);

                if (clickedBlock) {
                    onBlockSelect(clickedBlock);
                } else {
                    onBlockSelect(null);
                    setSelectedBlocks([]);
                }
            }

            onSeek(Math.max(0, Math.min(duration, clickTime)));
        }
    };


    const performCut = async (cutTime, speaker) => {
        try {
            // Activez le flag pour bloquer le curseur
            setIsCutProcessing(true);

            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(`${API_CONFIG.BASE_URL}/cut_audio/${projectId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    transcript: transcript,
                    cutTime: cutTime,
                    speaker: speaker,
                    prioritizeOriginalSpeaker: true
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Network response was not ok');
            }

            const result = await response.json();

            // Identifier uniquement les segments modifiés
            const modifiedSegments = findModifiedSegments(transcript, result.updatedTranscript);

            // Mettre à jour le transcript complet
            onTranscriptUpdate(result.updatedTranscript);

            // Recharger uniquement les segments modifiés
            const speakersToReload = [];

            // Pour chaque segment modifié, déterminer le bon speaker pour le rechargement audio
            modifiedSegments.forEach(segment => {
                // Utiliser original_speaker pour le chemin audio si disponible
                const audioPathSpeaker = segment.original_speaker || segment.speaker;
                if (!speakersToReload.includes(audioPathSpeaker)) {
                    speakersToReload.push(audioPathSpeaker);
                }
            });

            // Recharger l'audio pour chaque speaker concerné
            speakersToReload.forEach(audioSpeaker => {
                const speakerSegments = modifiedSegments.filter(seg =>
                    (seg.original_speaker || seg.speaker) === audioSpeaker
                );

                speakerSegments.forEach(segment => {
                    if (handleAudioReload) {
                        // Utiliser le speaker pour les chemins d'accès aux fichiers audio
                        handleAudioReload(audioSpeaker, segment);
                    }
                });
            });

        } catch (error) {
            console.error('Error:', error);
        } finally {
            // Désactivez le flag pour débloquer le curseur (que l'opération ait réussi ou échoué)
            setIsCutProcessing(false);

        }
    };



    const getColor = (speaker) => {
        const colors = ["#4B90F5", "#45C75E", "#F5A623", "#FF6B6B"];  // Bleu, Vert, Orange, Rouge 

        // Obtenir l'ordre des speakers à partir du transcript
        const orderedSpeakers = [...new Set(transcript.map(item => item.speaker))].sort();
        const speakerIndex = orderedSpeakers.indexOf(speaker);

        return colors[speakerIndex % colors.length];
    };

    const handleZoomChange = (event, newValue) => {
        const oldZoom = zoom;
        setZoom(newValue);
        if (timelineRef.current) {
            const visibleCenter = scrollPercentage + (1 / (2 * oldZoom));
            const newScrollPercentage = visibleCenter - (1 / (2 * newValue));
            setScrollPercentage(Math.max(0, Math.min(1 - (1 / newValue), newScrollPercentage)));
            timelineRef.current.scrollLeft = newScrollPercentage * timelineRef.current.scrollWidth;
        }
    };

    const handleScroll = (event) => {
        const maxScroll = event.target.scrollWidth - event.target.clientWidth;
        setScrollPercentage(event.target.scrollLeft / maxScroll);
    };

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        const ms = Math.floor((seconds % 1) * 100);
        return `${mins}:${secs.toString().padStart(2, '0')}.${ms.toString().padStart(2, '0')}`;
    };

    const renderTimeScale = () => {
        const visibleDuration = duration / zoom;

        // Ajuster l'intervalle en fonction du zoom
        let interval;
        if (visibleDuration <= 30) {
            interval = 1;
        } else if (visibleDuration <= 60) {
            interval = 5;
        } else if (visibleDuration <= 300) {
            interval = 15;
        } else if (visibleDuration <= 900) {
            interval = 30;
        } else {
            interval = 60;
        }

        const timeScaleMarkers = [];
        const numMarkers = Math.ceil(duration / interval);
        const minSpaceBetweenLabels = 50; // Espace minimum en pixels entre les labels
        const timelineWidth = timelineRef.current?.clientWidth || 1000;
        const pixelsPerPercent = timelineWidth / 100;

        for (let i = 0; i <= numMarkers; i++) {
            const time = i * interval;
            if (time <= duration) {
                const left = `${(time / duration) * 100}%`;
                const leftPixels = (time / duration) * 100 * pixelsPerPercent * zoom;

                // Vérifier si ce marqueur serait trop proche du précédent
                const previousMarkerPixels = ((i - 1) * interval / duration) * 100 * pixelsPerPercent * zoom;
                const shouldShow = i === 0 || (leftPixels - previousMarkerPixels) >= minSpaceBetweenLabels;

                timeScaleMarkers.push(
                    <Box
                        key={time}
                        sx={{
                            position: 'absolute',
                            left: left,
                            transform: 'translateX(-50%)',
                            marginTop: 1,
                            height: TIMESCALE_HEIGHT + 1,
                            display: shouldShow ? 'flex' : 'none',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            variant="caption"
                            sx={{
                                fontSize: '0.65rem',
                                color: 'rgba(255,255,255,0.7)',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {formatTime(time)}
                        </Typography>
                        <Box
                            sx={{
                                width: 1,
                                height: 5,
                                bgcolor: 'rgba(255,255,255,0.5)',
                                marginTop: '2px'
                            }}
                        />
                    </Box>
                );
            }
        }

        return (
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden'
                }}
            >
                {timeScaleMarkers}
            </Box>
        );
    };

    const toggleTranslatedText = () => {
        setShowTranslatedText(!showTranslatedText);
    };

    const lightenColor = (color, amount) => {
        return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
    };

    const handleBlockSelect = (block, event) => {
        if (event.ctrlKey || event.metaKey) {
            setSelectedBlocks(prev => {
                if (prev.includes(block)) {
                    return prev.filter(b => b !== block);
                } else {
                    return [...prev, block].slice(-2);
                }
            });
        } else {
            setSelectedBlocks([block]);
        }
        onBlockSelect(block);
    };

    const handleDeleteClick = useCallback(async (e) => {
        e.stopPropagation();
        let updatedTranscript = transcript;
        if (selectedBlocks.length > 0) {
            updatedTranscript = transcript.filter(block => !selectedBlocks.includes(block));
            onTranscriptUpdate(updatedTranscript);
            setSelectedBlocks([]);

            try {
                await fetch(`${API_CONFIG.BASE_URL}/delete_segment/${projectId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({ segments: selectedBlocks })
                });
            } catch (error) {
                console.error('Error deleting segments:', error);
            }
        } else if (selectedBlock) {
            updatedTranscript = transcript.filter(block => block !== selectedBlock);
            onTranscriptUpdate(updatedTranscript);
            onBlockSelect(null);

            try {
                await fetch(`${API_CONFIG.BASE_URL}/delete_segment/${projectId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({ segments: [selectedBlock] })
                });
            } catch (error) {
                console.error('Error deleting segment:', error);
            }
        }
        await onSave(updatedTranscript);

    }, [transcript, selectedBlocks, selectedBlock, onTranscriptUpdate, onBlockSelect, projectId]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Delete') {
                handleDeleteClick(event);
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleDeleteClick]);


    // Auto scroll 
    useEffect(() => {
        if (isPlaying && !isCutMode && !isDragging && !isMouseDown && timelineRef.current) {
            const timelineWidth = timelineRef.current.clientWidth;
            const totalWidth = timelineRef.current.scrollWidth;
            const currentScrollLeft = timelineRef.current.scrollLeft;
            const cursorPositionPixel = (cursorPosition / duration) * totalWidth;
            const visibleLeftEdge = currentScrollLeft;
            const visibleRightEdge = currentScrollLeft + timelineWidth;
            const scrollMargin = timelineWidth * 0.2;

            // Vérifier si le curseur est hors de la zone visible avec une marge
            if (cursorPositionPixel < visibleLeftEdge + scrollMargin ||
                cursorPositionPixel > visibleRightEdge - scrollMargin) {

                // Calculer la nouvelle position de scroll
                const newScrollLeft = Math.max(
                    0,
                    Math.min(
                        totalWidth - timelineWidth,
                        cursorPositionPixel - (timelineWidth / 2) // Centrer le curseur
                    )
                );

                // Utiliser scrollTo avec behavior: 'smooth' pour un défilement fluide
                timelineRef.current.scrollTo({
                    left: newScrollLeft,
                    behavior: 'smooth'
                });
            }
        }
    }, [currentTime, isCutMode, isDragging, isMouseDown, duration, cursorPosition]);

    const handleMergeBlocks = async () => {
        if (selectedBlocks.length !== 2) {
            alert("Veuillez sélectionner exactement deux blocs à fusionner.");
            return;
        }

        const [block1, block2] = selectedBlocks;

        try {
            const response = await fetch(`${API_CONFIG.BASE_URL}/merge_blocks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    project_id: projectId,
                    transcript: transcript,
                    block1SegmentFile: block1.segment_file,
                    block2SegmentFile: block2.segment_file,
                    prioritizeOriginalSpeaker: true // Flag pour utiliser original_speaker pour les fichiers
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();

            // Identifier uniquement le nouveau segment fusionné
            const modifiedSegments = findModifiedSegments(transcript, result.updatedTranscript);

            // Mettre à jour le transcript complet
            onTranscriptUpdate(result.updatedTranscript);

            // Recharger uniquement le nouveau segment fusionné
            if (modifiedSegments.length > 0) {
                const newSegment = modifiedSegments[0]; // Il n'y aura qu'un seul nouveau segment
                if (handleAudioReload) {
                    // Utiliser original_speaker pour le chemin audio si disponible
                    const audioPathSpeaker = newSegment.original_speaker || newSegment.speaker;
                    handleAudioReload(audioPathSpeaker, newSegment);
                }
            }

        } catch (error) {
            console.error('Error:', error);
            alert("Une erreur s'est produite lors de la fusion des blocs.");
        }
    };

    const renderBlock = (block, index, speaker) => {
        const isInSelection = selectedBlocks.includes(block);
        const isHovered = hoveredBlock === block;
        const left = `${(block.start / duration) * 100}%`;
        const width = `${((block.end - block.start) / duration) * 100}%`;
        const baseColor = getColor(speaker);
        const color = isInSelection ? '#6366f1' : isHovered ? lightenColor(PRIMARY_COLOR, 40) : baseColor;
        const borderColor = lightenColor(color, 40);
        const textToShow = showTranslatedText ? block.translated_text : block.text;

        const gain = block.audio_gain !== undefined ? block.audio_gain : 1.0;
        const opacityBasedOnGain = 0.5 + (gain * 0.5); // Entre 0.5 et 1.0 pour la visibilité

        return (
            <Tooltip
                key={index}
                title={
                    <div>
                        <div>{textToShow}</div>
                    </div>
                }
                placement="top"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        left: left,
                        width: width,
                        height: '100%',
                        bgcolor: color,
                        opacity: 0.9,
                        cursor: isCutMode ? 'crosshair' : 'move',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        overflow: 'hidden',
                        border: `1px solid ${borderColor}`,
                        boxSizing: 'border-box',
                        borderRadius: '2px',
                        boxShadow: isInSelection ? '0 0 0 1px rgba(255,255,255,0.5)' : 'none',
                        '&:hover': {
                            boxShadow: '0 0 0 1px rgba(255,255,255,0.7)',
                        },
                    }}
                    onMouseDown={(e) => handleMouseDown(e, block)}
                    onClick={(e) => handleBlockSelect(block, e)}
                    onMouseEnter={() => setHoveredBlock(block)}
                    onMouseLeave={() => setHoveredBlock(null)}
                >
                    <WaveformDisplay
                        ref={(el) => {
                            const key = `${block.speaker}-${block.segment_file}`;
                            if (!waveformRefs.current) waveformRefs.current = {};
                            waveformRefs.current[key] = el;
                        }}
                        waveformFile={block.waveform}
                        color={color}
                        projectId={projectId}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            padding: '2px',
                            fontSize: '10px',
                            color: 'white',
                            textShadow: '0px 0px 2px rgba(0,0,0,0.7)',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            backgroundColor: 'rgba(0,0,0,0.5)',
                        }}
                    >
                        {textToShow}
                    </Box>
                </Box>
            </Tooltip>
        );
    };

    const handleGainChange = (value) => {
        if (!selectedBlock) return;

        const updatedTranscript = transcript.map(block => {
            if (block === selectedBlock) {
                return { ...block, audio_gain: value };
            }
            return block;
        });

        onTranscriptUpdate(updatedTranscript);
        // Pas besoin de sauvegarder immédiatement, on le fera quand l'utilisateur relâche le slider
    };

    const handleGainChangeCommitted = () => {
        if (selectedBlock) {
            onSave();
        }
    };

    const formatSpeakerName = (speaker) => {
        if (speaker.startsWith('SPEAKER')) {
            const number = parseInt(speaker.split('_')[1]) + 1;
            return `SPEAKER ${number}`;
        }
        if (speaker.startsWith('BACKGROUND')) {
            const number = parseInt(speaker.split('_')[1]) + 1;
            return `BACKGROUND ${number}`;
        }
        return speaker;
    };

    return (
        <Box ref={containerRef} sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', bgcolor: '#1E1E1E', userSelect: 'none' }}>
            <Box sx={{ display: 'flex', flexGrow: 1, height: '100%' }}>
                {/* Section des speakers */}
                <Box sx={{
                    width: SPEAKER_LABEL_WIDTH,
                    flexShrink: 0,
                    bgcolor: '#2D2D2D',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    {/* En-tête vide */}
                    <Box sx={{
                        height: TIMESCALE_HEIGHT + 10,
                        borderBottom: '1px solid #3A3A3A',
                        borderRight: '1px solid #3A3A3A'
                    }} />

                    {/* Liste des speakers */}
                    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        {speakerOrder.map((speaker) => (
                            <Box
                                key={speaker}
                                sx={{
                                    height: TRACK_HEIGHT,
                                    display: 'flex',
                                    alignItems: 'center',
                                    px: 1.5,
                                    borderBottom: '1px solid #3A3A3A',
                                    borderRight: '1px solid #3A3A3A',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <IconButton
                                        onClick={() => toggleMute(speaker)}
                                        size="small"
                                        sx={{
                                            color: 'rgba(255,255,255,0.6)',
                                            padding: '4px',
                                            '&:hover': {
                                                color: PRIMARY_COLOR
                                            }
                                        }}
                                    >
                                        {mutedTracks[speaker] ? <VolumeOffIcon fontSize="small" /> : <VolumeUpIcon fontSize="small" />}
                                    </IconButton>
                                    <Typography sx={{ color: 'rgba(255,255,255,0.8)', fontSize: '0.8rem' }}>
                                        {formatSpeakerName(speaker)}
                                    </Typography>
                                </Box>
                                <IconButton
                                    onClick={() => removeSpeaker(speaker)}
                                    size="small"
                                    sx={{
                                        color: 'rgba(255,255,255,0.6)',
                                        padding: '4px',
                                        opacity: speakerOrder.length <= 1 ? 0.3 : 1,
                                        '&:hover': {
                                            color: '#FF6B6B'
                                        },
                                        '&.Mui-disabled': {
                                            opacity: 0.3
                                        }
                                    }}
                                    disabled={speakerOrder.length <= 1}
                                >
                                    <RemoveIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        ))}
                    </Box>

                    {/* Bouton d'ajout de piste en bas */}
                    <Box
                        sx={{
                            borderTop: '1px solid #3A3A3A',
                            borderRight: '1px solid #3A3A3A',
                            py: 1
                        }}
                    >
                        <Button
                            onClick={handleAddSpeaker}
                            startIcon={<AddIcon />}
                            sx={{
                                width: '90%',
                                mx: 'auto',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                color: 'rgba(255, 255, 255, 0.8)',
                                fontSize: '0.75rem',
                                textTransform: 'none',
                                py: 0.5,
                                '&:hover': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    '& .MuiSvgIcon-root': {
                                        color: PRIMARY_COLOR
                                    }
                                }
                            }}
                        >
                            Add track
                        </Button>
                    </Box>
                </Box>

                {/* Section de la timeline */}
                <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
                    <Box
                        ref={timelineRef}
                        sx={{
                            width: '100%',
                            height: '100%',
                            bgcolor: '#2D2D2D',
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            '&::-webkit-scrollbar': {
                                height: '10px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#555',
                                borderRadius: '5px',
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: '#333',
                                borderRadius: '5px',
                            },
                        }}
                        onScroll={handleScroll}
                        onClick={handleTimelineClick}
                    >
                        <Box sx={{ width: `${100 * zoom}%`, height: '100%', position: 'relative' }}>
                            <Box sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: TIMESCALE_HEIGHT + 10,
                                borderBottom: '1px solid #3A3A3A',
                                zIndex: 1
                            }}>
                                {renderTimeScale()}
                            </Box>
                            {speakerOrder.map((speaker, speakerIndex) => (
                                <Box
                                    key={speaker}
                                    sx={{
                                        position: 'absolute',
                                        top: speakerIndex * TRACK_HEIGHT + TIMELINE_PADDING + TIMESCALE_HEIGHT,
                                        left: 0,
                                        width: '100%',
                                        height: TRACK_HEIGHT,
                                        bgcolor: '#252525',
                                        borderBottom: '1px solid #3A3A3A',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {transcript.filter(t => t.speaker === speaker).map((t, tIndex) => renderBlock(t, tIndex, speaker))}
                                </Box>
                            ))}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: `${(cursorPosition / duration) * 100}%`,
                                    top: 0,
                                    bottom: 0,
                                    width: CURSOR_WIDTH,
                                    bgcolor: isCutMode
                                        ? (isCutProcessing ? '#999999' : '#FF6B6B') // Gris si en cours de traitement, rouge sinon
                                        : PRIMARY_COLOR,
                                    opacity: isCutProcessing && isCutMode ? '0.5' : '0.8', // Moins opaque si en traitement
                                    pointerEvents: 'none',
                                    zIndex: 2,
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            {/* Barre d'outils en bas */}
            <Box sx={{ display: 'flex', alignItems: 'center', p: 1, bgcolor: '#2D2D2D' }}>
                <IconButton size="small" onClick={() => handleZoomChange(null, Math.max(1, zoom - 1))} sx={{ color: 'rgba(255,255,255,0.7)' }}>
                    <ZoomOutIcon fontSize="small" />
                </IconButton>
                <Slider
                    value={zoom}
                    onChange={handleZoomChange}
                    min={1}
                    max={8}
                    step={0.1}
                    sx={{
                        mx: 1,
                        width: 100,
                        color: PRIMARY_COLOR,
                        '& .MuiSlider-thumb': {
                            width: 12,
                            height: 12,
                        },
                        '& .MuiSlider-rail': {
                            opacity: 0.3,
                        },
                    }}
                />
                <IconButton size="small" onClick={() => handleZoomChange(null, Math.min(20, zoom + 1))} sx={{ color: 'rgba(255,255,255,0.7)' }}>
                    <ZoomInIcon fontSize="small" />
                </IconButton>
                <IconButton
                    size="small"
                    onClick={handleCutModeToggle}
                    disabled={isCutProcessing}
                    sx={{
                        ml: 2,
                        color: isCutMode ? '#FF6B6B' : 'rgba(255,255,255,0.7)',
                        opacity: isCutProcessing ? 0.5 : 1,
                    }}
                >
                    <ContentCutIcon fontSize="small" />
                </IconButton>
                <IconButton
                    size="small"
                    onClick={handleDeleteClick}
                    disabled={!selectedBlock && selectedBlocks.length === 0}
                    sx={{
                        ml: 2,
                        color: selectedBlock || selectedBlocks.length > 0 ? 'rgba(255,255,255,0.7)' : 'rgba(255,255,255,0.3)',
                        '&.Mui-disabled': {
                            color: 'rgba(255,255,255,0.3)',
                        }
                    }}
                >
                    <DeleteIcon fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={toggleTranslatedText} sx={{ ml: 2, color: showTranslatedText ? PRIMARY_COLOR : 'rgba(255,255,255,0.7)' }}>
                    <TranslateIcon fontSize="small" />
                </IconButton>
                <IconButton
                    size="small"
                    onClick={handleMergeBlocks}
                    disabled={selectedBlocks.length !== 2}
                    sx={{
                        ml: 2,
                        color: selectedBlocks.length === 2 ? PRIMARY_COLOR : 'rgba(255,255,255,0.3)',
                        '&.Mui-disabled': {
                            color: 'rgba(255,255,255,0.3)',
                        }
                    }}
                >
                    <MergeTypeIcon fontSize="small" />
                </IconButton>
                {hasVideo && (
                    <Box
                        onClick={toggleVideoAudio}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            ml: 2,
                            cursor: 'pointer',
                            bgcolor: 'rgba(255, 255, 255, 0.1)',
                            color: 'rgba(255,255,255,0.7)',
                            borderRadius: '20px',
                            padding: '2px 10px',
                            transition: 'all 0.3s ease',
                        }}
                    >
                        {isVideoAudioEnabled ? <PersonIcon fontSize="small" /> : <SmartToyIcon fontSize="small" />}
                        <Typography
                            variant="caption"
                            sx={{
                                ml: 0.8,
                                mt: 0.30,
                                fontSize: '0.70rem',
                                fontWeight: 'bold',
                            }}
                        >
                            {isVideoAudioEnabled ? 'Original voice' : 'IA voice'}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box >
    );
}

export default Timeline;