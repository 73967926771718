import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-container">
            <div className="privacy-content">
                <h1>Politique de Confidentialité</h1>
                <p className="last-updated">Dernière mise à jour : 10 Mars 2025</p>

                <section className="privacy-section">
                    <h2>1. Introduction</h2>
                    <p>
                        La présente politique de confidentialité décrit la manière dont Sumfy (nom commercial d'Arthur Diard, auto-entrepreneur) collecte, utilise et protège les données personnelles de ses utilisateurs.
                        <br />
                        En utilisant nos services, vous acceptez cette politique et le traitement de vos données personnelles tel que décrit ci-dessous.
                    </p>
                </section>

                <section className="privacy-section">
                    <h2>2. Données collectées</h2>
                    <p>
                        Nous collectons uniquement les données suivantes :
                    </p>
                    <ul>
                        <li>Adresse email : nécessaire pour la création et la gestion du compte utilisateur.</li>
                        <li>Vidéos téléchargées : nécessaires pour permettre l’accès aux vidéos traduites dans l’espace personnel de l’utilisateur.</li>
                    </ul>
                </section>

                <section className="privacy-section">
                    <h2>3. Finalités du traitement des données</h2>
                    <p>
                        Les données collectées sont utilisées exclusivement pour les finalités suivantes :
                    </p>
                    <ul>
                        <li>Création et gestion des comptes utilisateurs.</li>
                        <li>Stockage temporaire des vidéos afin de permettre leur traitement et leur accès dans l’espace utilisateur.</li>
                        <li>Communication avec l’utilisateur en cas de besoin.</li>
                    </ul>
                </section>

                <section className="privacy-section">
                    <h2>4. Base légale du traitement</h2>
                    <p>
                        Le traitement des données repose sur le consentement des utilisateurs, conformément au Règlement Général sur la Protection des Données (RGPD).
                    </p>
                </section>

                <section className="privacy-section">
                    <h2>5. Durée de conservation des données</h2>
                    <ul>
                        <li>Adresses emails : conservées jusqu’à la suppression du compte ou après 24 mois d’inactivité.</li>
                        <li>Vidéos téléchargées : conservées dans l’espace personnel de l’utilisateur jusqu’à la suppression manuelle par l’utilisateur ou après 24 mois d’inactivité.</li>
                    </ul>
                </section>

                <section className="privacy-section">
                    <h2>6. Partage des données</h2>
                    <p>
                        Les données personnelles collectées ne sont en aucun cas vendues, louées ou partagées avec des tiers. Elles sont exclusivement utilisées pour fournir le service proposé par Sumfy.
                    </p>
                </section>

                <section className="privacy-section">
                    <h2>7. Sécurité des données</h2>
                    <p>
                        Nous mettons en place des mesures techniques et organisationnelles appropriées pour assurer la sécurité des données et empêcher tout accès non autorisé, perte ou divulgation.
                    </p>
                </section>

                <section className="privacy-section">
                    <h2>8. Droits des utilisateurs</h2>
                    <p>
                        Conformément au RGPD, vous disposez des droits suivants sur vos données personnelles :
                    </p>
                    <ul>
                        <li>Droit d’accès : obtenir une copie des données collectées.</li>
                        <li>Droit de rectification : corriger des données inexactes ou incomplètes.</li>
                        <li>Droit à l’effacement : demander la suppression des données.</li>
                        <li>Droit à la limitation du traitement : restreindre l’utilisation des données dans certains cas.</li>
                        <li>Droit à la portabilité : recevoir ses données dans un format exploitable.</li>
                    </ul>
                    <p>
                        Vous pouvez exercer ces droits en nous contactant à l’adresse suivante : <a href="mailto:contact.sumfy@gmail.com">contact.sumfy@gmail.com</a>.
                    </p>
                </section>

                <section className="privacy-section">
                    <h2>9. Modification de la politique de confidentialité</h2>
                    <p>
                        Nous nous réservons le droit de modifier la présente politique de confidentialité à tout moment. Toute modification sera publiée sur cette page et prendra effet immédiatement.
                    </p>
                </section>

                <section className="privacy-section">
                    <h2>10. Contact</h2>
                    <p>
                        Pour toute question concernant cette politique de confidentialité, vous pouvez nous contacter à l’adresse suivante :
                        <br />
                        📧 <a href="mailto:contact.sumfy@gmail.com">contact.sumfy@gmail.com</a>
                    </p>
                </section>
            </div>
        </div>
    );
};

export default PrivacyPolicy;