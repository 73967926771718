import React, { useCallback, useState, useEffect } from 'react';
import Sidebar from '../../components/Dashboard/Sidebar';
import ProjectGrid from '../../components/Dashboard/ProjectGrid';
import NewProjectModalV2 from '../../components/Dashboard/NewProjectModalV2'; // Mise à jour vers V2
import FiltersModal from '../../components/Dashboard/FiltersModal';
import { projectService } from '../../services/project.service.js';
import SubscriptionService from '../../services/subscription.service';
import './DashboardPage.css';
import '../../components/Dashboard/FiltersModal.css';
import FingerprintService from '../../services/fingerprint.service';

const DashboardPage = () => {
    // États pour les modaux
    const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);

    // État pour le rafraîchissement des projets
    const [shouldRefreshProjects, setShouldRefreshProjects] = useState(false);

    // États pour les données
    const [languages, setLanguages] = useState([]);
    const [userCoins, setUserCoins] = useState(0);
    const [userPlan, setUserPlan] = useState('free');
    const [filters, setFilters] = useState({
        search: '',
        statuses: [],
        languages: [],
        dateRange: {
            start: '',
            end: ''
        },
        duration: {
            min: '',
            max: ''
        }
    });

    // Gestionnaire de recherche
    const handleSearchChange = useCallback((e) => {
        setFilters(prev => ({
            ...prev,
            search: e.target.value
        }));
    }, []);

    // Gestionnaire pour la création de projet - reste identique
    const handleProjectCreated = useCallback(() => {
        console.log("DashboardPage: handleProjectCreated appelé - déclenche le rafraîchissement");
        setShouldRefreshProjects(true);
    }, []);

    // Nouveau gestionnaire pour la mise à jour des tokens
    const handleCoinsUpdated = useCallback((newCoins) => {
        console.log("DashboardPage: Mise à jour des tokens:", newCoins);
        setUserCoins(newCoins);
    }, []);

    // Gestionnaire pour la fin du rafraîchissement - reste identique
    const handleRefreshComplete = useCallback(() => {
        console.log("DashboardPage: handleRefreshComplete appelé - fin du rafraîchissement");
        setShouldRefreshProjects(false);
    }, []);

    // Chargement initial des langues disponibles
    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const languagesData = await projectService.getLanguages();
                setLanguages(languagesData);
            } catch (error) {
                console.error('Erreur lors du chargement des langages:', error);
                // Option: Ajouter un state pour gérer l'erreur de chargement des langues
            }
        };

        fetchLanguages();
    }, []);

    // Fonction pour récupérer les tokens de l'utilisateur
    const updateCoins = async () => {
        try {
            const coinsData = await FingerprintService.getUserCoins();
            setUserCoins(coinsData.coins);
        } catch (error) {
            console.error('Error updating coins:', error);
        }
    };

    // Chargement des informations de l'utilisateur (tokens et plan)
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                // Vérifier si l'utilisateur est connecté
                const token = localStorage.getItem('token');
                if (!token) {
                    console.log('Utilisateur non connecté, utilisation des valeurs par défaut');
                    return;
                }

                // Récupérer les informations d'abonnement
                const subscription = await SubscriptionService.getCurrentSubscription();
                if (subscription) {
                    if (subscription.planId) {
                        setUserPlan(subscription.planId);
                        console.log('Plan d\'abonnement récupéré:', subscription.planId);
                    }
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des informations utilisateur:', error);
                // En cas d'erreur, on garde les valeurs par défaut
            }
        };

        updateCoins();
        fetchUserInfo();
    }, []);

    // Calcul du nombre de filtres actifs
    const activeFiltersCount = filters.statuses.length + filters.languages.length;

    return (
        <div className="app-container">
            {/* Sidebar */}
            <Sidebar />

            {/* Contenu principal */}
            <main className="main-content">
                {/* En-tête avec filtres */}
                <header className="content-header">
                    <h1 className="content-title">Mes Projets</h1>

                    <div className="header-actions">
                        {/* Barre de recherche */}
                        <div className="filter-group">
                            <i className="fas fa-search"></i>
                            <input
                                type="text"
                                className="search-input"
                                placeholder="Rechercher un projet..."
                                value={filters.search}
                                onChange={handleSearchChange}
                            />
                        </div>

                        {/* Bouton des filtres avec badge */}
                        <div className="filters-dropdown">
                            <button
                                className="action-btn btn-secondary"
                                onClick={() => setIsFiltersOpen(true)}
                            >
                                <i className="fas fa-filter"></i>
                                Filtres
                                {activeFiltersCount > 0 && (
                                    <span className="filter-badge">
                                        {activeFiltersCount}
                                    </span>
                                )}
                            </button>

                            {/* Modal des filtres */}
                            <FiltersModal
                                isOpen={isFiltersOpen}
                                onClose={() => setIsFiltersOpen(false)}
                                filters={filters}
                                onFiltersChange={setFilters}
                                languages={languages}
                            />
                        </div>
                    </div>
                </header>

                {/* Grille des projets */}
                <ProjectGrid
                    shouldRefresh={shouldRefreshProjects}
                    onRefreshComplete={handleRefreshComplete}
                    filters={filters}
                />
            </main>

            {/* Bouton flottant d'ajout de projet */}
            <button
                className="floating-action-btn"
                onClick={() => setIsProjectModalOpen(true)}
                title="Nouveau projet"
            >
                <i className="fas fa-plus"></i>
            </button>

            {/* Modal de création de projet V2 */}
            <NewProjectModalV2
                isOpen={isProjectModalOpen}
                onClose={() => setIsProjectModalOpen(false)}
                onProjectCreated={handleProjectCreated}
                onCoinsUpdated={handleCoinsUpdated}
                allLanguages={languages}
                userCoins={userCoins}
                userPlan={userPlan}
            />
        </div>
    );
};

export default DashboardPage;