import React, { useEffect, useState } from 'react';
import FingerprintService from '../../services/fingerprint.service.js';
import Navbar from '../../components/Navbar/Navbar.jsx';
import Hero from '../../components/Hero/Hero.jsx';
import Features from '../../components/Features/Features.jsx';
import Stats from '../../components/Stats/Stats.jsx';
import CTA from '../../components/CTA/CTA.jsx';
import Footer from '../../components/Footer/Footer.jsx';
import '../../styles/global.css';
import SubscriptionService from '../../services/subscription.service.js';

const LandingPage = () => {
    const [userCoins, setUserCoins] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const initializeUserData = async () => {
            try {
                setLoading(true);
                await FingerprintService.initializeUser();
                const coinsData = await FingerprintService.getUserCoins();
                setUserCoins(coinsData.coins);
                console.log('User coins:', coinsData.coins);
            } catch (error) {
                console.error('Error initializing user data:', error);
                setUserCoins(0);
            } finally {
                setLoading(false);
            }
        };

        initializeUserData();
    }, []);

    // Fonction pour mettre à jour les coins après une action
    const updateCoins = async () => {
        try {
            const coinsData = await FingerprintService.getUserCoins();
            setUserCoins(coinsData.coins);
        } catch (error) {
            console.error('Error updating coins:', error);
        }
    };


    const handleTestCheckout = async () => {
        try {
            await SubscriptionService.testCheckout();
        } catch (error) {
            console.error("Test checkout failed:", error);
            alert(`Erreur lors du test: ${error.message}`);
        }
    };

    return (
        <div className="landing-page">
            <div className="glass-bg">
                <div className="glass-blob blob-1"></div>
                <div className="glass-blob blob-2"></div>
            </div>
            <Navbar
                userCoins={userCoins}
                onCoinsUpdate={updateCoins}
            />
            <Hero
                userCoins={userCoins}
            />
            <Features />
            <Stats />
            <CTA />
            <Footer />
        </div>
    );
};

export default LandingPage;