import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
    return (
        <div className="terms-container">
            <div className="terms-content">
                <h1>Conditions Générales d'Utilisation (CGU)</h1>
                <p className="last-updated">Dernière mise à jour : 10 Mars 2025</p>

                <section className="terms-section">
                    <h2>1. Introduction</h2>
                    <p>
                        Les présentes Conditions Générales d'Utilisation (CGU) régissent l'accès et l'utilisation du site www.sumfy.com (ci-après "le Site"), édité par Arthur Diard, dont le nom commercial est Sumfy. En accédant au Site, vous acceptez sans réserve les termes des présentes CGU.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>2. Objet</h2>
                    <p>
                        Le Site a pour objet de fournir des services de traduction vidéo et audio en ligne. En utilisant ce Site, vous acceptez d'utiliser les services dans les conditions définies par les présentes CGU.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>3. Accès au site</h2>
                    <p>
                        L'accès au Site est libre et gratuit. Toutefois, certains services peuvent nécessiter une inscription préalable ou l'adhésion à un abonnement payant. L'accès au Site peut être temporairement suspendu pour des raisons de maintenance, de mise à jour, ou de force majeure.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>4. Inscription et création de compte</h2>
                    <p>
                        Pour utiliser certains services, vous devez créer un compte sur le Site. Lors de votre inscription, vous vous engagez à fournir des informations exactes et à maintenir ces informations à jour. Vous êtes responsable de la confidentialité de vos identifiants et de toute activité effectuée avec votre compte.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>5. Utilisation des services</h2>
                    <p>
                        Les services du Site permettent aux utilisateurs de traduire des vidéos et audios dans diverses langues. Vous vous engagez à utiliser ces services conformément aux lois en vigueur et à ne pas violer les droits de propriété intellectuelle de tiers.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>6. Propriété intellectuelle</h2>
                    <p>
                        Tous les contenus présents sur le Site (textes, images, vidéos, logos, etc.) sont protégés par des droits de propriété intellectuelle et sont la propriété exclusive de Sumfy ou de ses partenaires. Toute reproduction, distribution ou utilisation non autorisée de ces contenus est interdite.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>7. Responsabilités de l’utilisateur</h2>
                    <p>
                        L'utilisateur est responsable de l'utilisation qu'il fait des services du Site. Il s'engage à ne pas utiliser le Site pour des activités illégales ou nuisibles. En cas de non-respect de ces conditions, Sumfy se réserve le droit de suspendre ou supprimer l'accès de l'utilisateur au Site.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>8. Politique des Cookies</h2>
                    <p>
                        Le Site utilise des cookies pour améliorer l'expérience utilisateur et garantir le bon fonctionnement du Site. Pour plus de détails sur les cookies utilisés et sur la manière de les gérer, veuillez consulter notre Politique des Cookies.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>9. Collecte et traitement des données personnelles</h2>
                    <p>
                        En accédant au Site, vous acceptez que Sumfy collecte et traite vos données personnelles conformément à notre Politique de Confidentialité. Nous nous engageons à protéger vos données personnelles et à les utiliser uniquement dans le cadre des services fournis par le Site.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>10. Modification des CGU</h2>
                    <p>
                        Sumfy se réserve le droit de modifier les présentes CGU à tout moment. Les modifications seront publiées sur cette page. Il est de votre responsabilité de consulter régulièrement les CGU afin de vous assurer que vous êtes informé de toute mise à jour.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>11. Limitation de responsabilité</h2>
                    <p>
                        Sumfy met tout en œuvre pour assurer l'exactitude des informations et la sécurité du Site. Toutefois, Sumfy ne saurait être tenu responsable des erreurs, omissions ou problèmes techniques liés à l'accès ou à l'utilisation du Site.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>12. Loi applicable et juridiction</h2>
                    <p>
                        Les présentes CGU sont régies par la législation française. En cas de litige, les tribunaux français seront seuls compétents.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>13. Licence de droits d’auteur</h2>
                    <p>
                        Version : 1.0
                    </p>
                    <p>
                        Le code source, les vidéos, les fichiers audio, ainsi que tous les composants et services associés de l'application Sumfy (ci-après dénommée "l'Application"), sont la propriété exclusive de Arthur Diard, sous le nom commercial Sumfy. Tous les droits sont réservés.
                    </p>
                    <h3>Licence d'utilisation :</h3>
                    <p>
                        En utilisant l'Application et ses services, l'utilisateur accepte de se conformer aux conditions suivantes.
                    </p>
                    <h4>a) Propriété et Usage</h4>
                    <ul>
                        <li>Le code source de l'Application, les services de traduction et de doublage vidéo/audio, ainsi que tous les éléments associés (textes, images, vidéos, fichiers audio) sont protégés par des droits d'auteur.</li>
                        <li>Licence limitée : L'utilisateur reçoit une licence non exclusive et non transférable pour utiliser l'Application à des fins personnelles ou commerciales, sous réserve de respecter les termes de cette licence.</li>
                        <li>Restrictions : L'utilisateur ne peut pas (i) modifier, décompiler, réverser l’ingénierie, désassembler, ou tenter de découvrir le code source de l'Application, (ii) distribuer, vendre ou transférer l'Application ou toute partie de son code à des tiers, (iii) utiliser l'Application de manière illégale, contraire aux bonnes mœurs ou en violation des lois locales et internationales.</li>
                    </ul>
                    <h4>b) Services de Traduction Vidéo/Audio</h4>
                    <ul>
                        <li>Les services de doublage et de traduction vidéo/audio fournis par Sumfy sont soumis aux conditions suivantes :</li>
                        <ul>
                            <li>L'utilisateur peut bénéficier d'une minute gratuite de doublage par vidéo. Après l'expiration de cette minute gratuite, des abonnements sont requis pour un accès prolongé.</li>
                            <li>L'utilisateur est responsable du contenu qu'il télécharge, téléchargeant uniquement des vidéos et des audios pour lesquels il détient tous les droits nécessaires.</li>
                            <li>Toute redistribution des traductions ou doublages réalisés via l'Application nécessite l'autorisation écrite de Arthur Diard.</li>
                        </ul>
                    </ul>
                    <h4>c) Protection des Données Personnelles</h4>
                    <ul>
                        <li>Conformément à la loi française sur la protection des données personnelles, l'Application collecte les informations suivantes : adresses e-mail et fichiers vidéo/audio. Ces données sont stockées dans un espace dédié pour une durée maximale de 24 mois d’inactivité. Après cette période, les données seront supprimées.</li>
                        <li>Confidentialité : Les utilisateurs doivent s'assurer de la confidentialité de leur compte et des informations qu'ils partagent. Sumfy ne sera pas responsable de toute utilisation non autorisée de ces informations par des tiers.</li>
                    </ul>
                    <h4>d) Exclusion de Garantie</h4>
                    <p>
                        L'Application et les services fournis sont offerts "en l'état", sans aucune garantie expresse ou implicite, y compris mais sans se limiter à des garanties implicites de qualité marchande ou d’adéquation à un usage particulier. Arthur Diard ne pourra être tenu responsable de tout dommage direct ou indirect résultant de l'utilisation ou de l'incapacité d'utiliser l'Application.
                    </p>
                    <h4>e) Déclaration d’Authenticité</h4>
                    <p>
                        Le code source de l'Application a été créé de manière originale par Arthur Diard, et il est protégé par les lois sur le droit d'auteur. Toute tentative de reproduction, redistribution ou modification sans l'accord explicite de l'auteur sera considérée comme une violation des droits d'auteur.
                    </p>
                    <h4>f) Durée de la Licence</h4>
                    <p>
                        Cette licence est accordée pour une durée indéterminée. Elle peut être révoquée en cas de violation des termes de cette licence. L'utilisateur doit cesser d'utiliser l'Application immédiatement en cas de révocation de la licence.
                    </p>
                    <h4>g) Propriété Intellectuelle</h4>
                    <p>
                        Tous les droits relatifs à l'Application, y compris les droits d'auteur, les marques et tous les autres droits de propriété intellectuelle, restent la propriété exclusive de Arthur Diard. L'utilisateur ne doit en aucun cas revendiquer la propriété ou l'exploitation commerciale de l'Application ou de ses composants.
                    </p>
                    <h4>h) Résolution des Conflits</h4>
                    <p>
                        En cas de différend concernant l'interprétation ou l'exécution de cette licence, les parties conviennent de tenter de résoudre le conflit à l'amiable. En cas d'échec, le litige sera soumis à la juridiction compétente de France.
                    </p>
                </section>

                <section className="terms-section">
                    <h2>14. Contact</h2>
                    <p>
                        Pour toute question concernant les présentes CGU, vous pouvez nous contacter à l'adresse suivante
                        <br />
                        📧 <a href="mailto:contact.sumfy@gmail.com">contact.sumfy@gmail.com</a>
                    </p>
                </section>
            </div>
        </div>
    );
};

export default TermsOfService;