import React from 'react';
import './CookiePolicy.css';

const CookiePolicy = () => {
    return (
        <div className="cookie-container">
            <div className="cookie-content">
                <h1>Politique des Cookies</h1>
                <p className="last-updated">Dernière mise à jour : 10 Mars 2025</p>

                <section className="cookie-section">
                    <h2>1. Introduction</h2>
                    <p>
                        Le site Sumfy utilise des cookies pour améliorer l’expérience utilisateur et assurer la sécurité des connexions.
                        Cette politique vise à expliquer quels cookies sont utilisés et comment vous pouvez gérer vos préférences.
                    </p>
                </section>

                <section className="cookie-section">
                    <h2>2. Quels cookies utilisons-nous ?</h2>

                    <h3>2.1 Cookies essentiels (stockés en cache)</h3>
                    <p>
                        Ces cookies sont nécessaires au bon fonctionnement du site et ne peuvent pas être désactivés :
                    </p>
                    <ul>
                        <li>Token de connexion : permet de maintenir la session active.</li>
                        <li>User ID : identifiant unique pour gérer les sessions utilisateur.</li>
                        <li>Username : stocké pour faciliter l’identification.</li>
                        <li>Fingerprint : utilisé pour des raisons de sécurité et de prévention de la fraude.</li>
                    </ul>

                    <h3>2.2 Cookies analytiques (tiers)</h3>
                    <p>
                        Nous utilisons Google Analytics pour collecter des informations anonymes sur la navigation des utilisateurs
                        (pages visitées, durée de visite, etc.).
                    </p>
                </section>

                <section className="cookie-section">
                    <h2>3. Consentement à l'utilisation des cookies</h2>
                    <p>
                        Lors de votre première visite sur notre site, un bandeau vous informe de l’utilisation des cookies.
                        Vous avez la possibilité de les accepter ou de les refuser. Les cookies essentiels ne peuvent pas être désactivés,
                        car ils garantissent le bon fonctionnement du site.
                    </p>
                </section>

                <section className="cookie-section">
                    <h2>4. Gestion et suppression des cookies</h2>
                    <p>
                        Vous pouvez à tout moment gérer vos préférences via les paramètres de votre navigateur :
                    </p>
                    <ul>
                        <li>Google Chrome : <a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noopener noreferrer">Instructions</a></li>
                        <li>Mozilla Firefox : <a href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies" target="_blank" rel="noopener noreferrer">Instructions</a></li>
                        <li>Safari : <a href="https://support.apple.com/fr-fr/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">Instructions</a></li>
                        <li>Microsoft Edge : <a href="https://support.microsoft.com/fr-fr/microsoft-edge/supprimer-les-cookies-dans-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank" rel="noopener noreferrer">Instructions</a></li>
                    </ul>
                </section>

                <section className="cookie-section">
                    <h2>5. Modification de la politique des cookies</h2>
                    <p>
                        Nous nous réservons le droit de modifier cette politique à tout moment. Toute modification sera publiée sur cette page.
                    </p>
                </section>

                <section className="cookie-section">
                    <h2>6. Contact</h2>
                    <p>
                        Pour toute question relative à cette politique, vous pouvez nous contacter à :
                        <br />
                        📧 <a href="mailto:contact.sumfy@gmail.com">contact.sumfy@gmail.com</a>
                    </p>
                </section>
            </div>
        </div>
    );
};

export default CookiePolicy;