import { loadStripe } from '@stripe/stripe-js';
import { API_CONFIG } from '../config/constants';
import FingerprintService from './fingerprint.service';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

class SubscriptionService {

    static async testCheckout() {
        try {
            const response = await fetch(`${API_CONFIG.BASE_URL}/test/checkout`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.error || 'Échec du test de checkout');
            }

            const { sessionId, checkoutUrl } = await response.json();

            // Option 1: Utiliser redirectToCheckout avec l'ID de session
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({
                sessionId
            });

            if (error) {
                throw new Error(error.message);
            }

            // Option 2 (alternative): Rediriger directement vers l'URL de checkout
            // window.location.href = checkoutUrl;

        } catch (error) {
            console.error('Error in test checkout:', error);
            throw error;
        }
    }

    static async getCurrentSubscription() {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Aucun token d\'authentification trouvé');
            }

            const response = await fetch(`${API_CONFIG.BASE_URL}/user/subscription`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    throw new Error('Session expirée');
                }
                const error = await response.json();
                throw new Error(error.error || 'Échec de la récupération de l\'abonnement');
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching subscription:', error);
            throw error;
        }
    }

    static async cancelSubscription() {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Aucun token d\'authentification trouvé');
            }

            const fingerprint = await FingerprintService.getFingerprint();

            const response = await fetch(`${API_CONFIG.BASE_URL}/user/cancel-subscription`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ fingerprint })
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.error || 'Échec de l\'annulation');
            }

            return await response.json();
        } catch (error) {
            console.error('Error canceling subscription:', error);
            throw error;
        }
    }

    static async subscribe(planId) {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Aucun token d\'authentification trouvé');
            }

            const fingerprint = await FingerprintService.getFingerprint();

            // Déterminer si c'est un plan gratuit (pour le logging)
            const isFree = planId.startsWith('free');
            console.log(`Subscribing to ${isFree ? 'free' : 'paid'} plan: ${planId}`);

            // Créer la session de paiement Stripe ou activer le plan gratuit
            const response = await fetch(`${API_CONFIG.BASE_URL}/user/subscribe`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    planId,
                    fingerprint
                })
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.error || 'Échec de la création de l\'abonnement');
            }

            const data = await response.json();

            // Si nous avons une redirection directe (plan gratuit)
            if (data.redirect) {
                console.log('Free plan activated, redirecting to:', data.redirect);
                return data;
            }

            // Sinon, continuer avec le processus Stripe pour les plans payants
            const { sessionId } = data;
            const stripe = await stripePromise;

            // Rediriger vers Stripe Checkout
            const { error } = await stripe.redirectToCheckout({
                sessionId
            });

            if (error) {
                throw new Error(error.message);
            }

        } catch (error) {
            console.error('Error subscribing:', error);
            throw error;
        }
    }

    static async getPendingSubscription() {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Aucun token d\'authentification trouvé');
            }

            const response = await fetch(`${API_CONFIG.BASE_URL}/user/pending-subscription`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    throw new Error('Session expirée');
                }
                const error = await response.json();
                throw new Error(error.error || 'Échec de la récupération de l\'abonnement en attente');
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching pending subscription:', error);
            throw error;
        }
    }

    static async subscribeToFreePlan() {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Aucun token d\'authentification trouvé');
            }

            const fingerprint = await FingerprintService.getFingerprint();

            const response = await fetch(`${API_CONFIG.BASE_URL}/user/free-subscription`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ fingerprint })
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.error || 'Échec de l\'activation du plan gratuit');
            }

            const data = await response.json();

            // Retourner les données avec le message au lieu d'utiliser alert
            return {
                ...data,
                message: data.message || "Votre abonnement actuel restera actif jusqu'à la fin de la période de facturation, puis passera automatiquement au Plan Gratuit."
            };
        } catch (error) {
            console.error('Error subscribing to free plan:', error);
            throw error;
        }
    }

    static async downgradeSubscription(newPlanId) {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Aucun token d\'authentification trouvé');
            }

            const fingerprint = await FingerprintService.getFingerprint();

            // Appel à l'endpoint de downgrade
            const response = await fetch(`${API_CONFIG.BASE_URL}/user/downgrade-subscription`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    newPlanId,
                    fingerprint
                })
            });

            if (!response.ok) {
                const error = await response.json();
                throw new Error(error.error || 'Échec du changement d\'abonnement');
            }

            const data = await response.json();
            console.log('Downgrade response:', data);

            // Retourner le message au lieu d'utiliser alert
            return {
                ...data,
                message: data.message || "Votre plan actuel restera actif jusqu'à la fin de la période de facturation, puis passera automatiquement au nouveau plan."
            };
        } catch (error) {
            console.error('Error downgrading subscription:', error);
            throw error;
        }
    }

    static async updateSubscription(newPlanId) {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Aucun token d\'authentification trouvé');
            }

            const fingerprint = await FingerprintService.getFingerprint();
            const currentSubscription = await this.getCurrentSubscription();

            // Vérifier si c'est le même plan
            if (currentSubscription && currentSubscription.planId === newPlanId) {
                throw new Error('Vous avez déjà souscrit à cet abonnement');
            }

            // Déterminer si c'est un downgrade ou un upgrade
            const currentPlanType = currentSubscription.planId ? currentSubscription.planId.split('-')[0] : '';
            const newPlanType = newPlanId.split('-')[0];

            const isDowngrade = (
                (currentPlanType === 'pro' && (newPlanType === 'classic' || newPlanType === 'free')) ||
                (currentPlanType === 'classic' && newPlanType === 'free')
            );

            const isPaidToFree =
                currentPlanType !== 'free' && newPlanType === 'free';

            // Si c'est un downgrade, utiliser l'endpoint de downgrade
            if ((isDowngrade || isPaidToFree) && currentPlanType !== 'free') {
                console.log('Détection de downgrade, redirection vers l\'endpoint de downgrade');
                return await this.downgradeSubscription(newPlanId);
            }

            // Déterminer si l'abonnement actuel est gratuit
            const isCurrentFree = currentSubscription.planId &&
                currentSubscription.planId.startsWith('free');

            // CAS SPÉCIAL: passage d'un plan gratuit à un plan payant
            if (isCurrentFree && newPlanType !== 'free') {
                console.log('Passage d\'un plan gratuit à un plan payant - Utilisation de subscribe');

                const response = await fetch(`${API_CONFIG.BASE_URL}/user/subscribe`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        planId: newPlanId,
                        fingerprint
                    })
                });

                if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.error || 'Échec de la souscription');
                }

                const data = await response.json();
                console.log('Response data (subscribe):', data);

                // Si nous avons une redirection directe
                if (data.redirect) {
                    return {
                        ...data,
                        message: "Votre abonnement a été mis à jour avec succès!"
                    };
                }

                // Sinon, continuer avec le processus Stripe pour les plans payants
                const { sessionId } = data;
                const stripe = await stripePromise;

                // Rediriger vers Stripe Checkout
                const { error } = await stripe.redirectToCheckout({
                    sessionId
                });

                if (error) {
                    throw new Error(error.message);
                }

                return;
            }

            // TOUS LES AUTRES CAS (upgrades): utiliser update-subscription
            console.log('Utilisation de update-subscription (upgrade)');

            const response = await fetch(`${API_CONFIG.BASE_URL}/user/update-subscription`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    newPlanId,
                    fingerprint
                })
            });

            if (!response.ok) {
                // Vérifier si la réponse est une redirection vers l'endpoint de downgrade
                if (response.status === 307) {
                    const data = await response.json();
                    if (data.redirect_to === "downgrade") {
                        console.log('Redirection détectée vers l\'endpoint de downgrade');
                        return await this.downgradeSubscription(newPlanId);
                    }
                }

                const error = await response.json();
                throw new Error(error.error || 'Échec de la mise à jour');
            }

            const data = await response.json();
            console.log('Response data (update):', data);

            // Si nous avons un sessionId
            if (data.sessionId) {
                console.log('Redirecting to Stripe with sessionId:', data.sessionId);
                const stripe = await stripePromise;
                const { error } = await stripe.redirectToCheckout({
                    sessionId: data.sessionId
                });

                if (error) {
                    throw new Error(error.message);
                }
                return;
            }

            // Si nous avons une redirection directe
            if (data.redirect) {
                return {
                    ...data,
                    message: "Votre abonnement a été mis à jour avec succès!"
                };
            }

            // Si c'est un succès sans redirection, retourner les données
            return {
                ...data,
                message: "Votre abonnement a été mis à jour avec succès!"
            };
        } catch (error) {
            console.error('Error updating subscription:', error);
            throw error;
        }
    }
}

export default SubscriptionService;