import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_CONFIG } from '../../config/constants';

const SuccessPage = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const activateSubscription = async () => {
            try {
                const params = new URLSearchParams(location.search);
                const sessionId = params.get('session_id');

                if (!sessionId) {
                    throw new Error('No session ID found');
                }

                const token = localStorage.getItem('token');
                const response = await fetch(`${API_CONFIG.BASE_URL}/subscription/success`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ session_id: sessionId })
                });

                if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.error || 'Failed to activate subscription');
                }

                // Rediriger vers le dashboard après 3 secondes
                setTimeout(() => {
                    navigate('/dashboard');
                }, 3000);

            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        activateSubscription();
    }, [location, navigate]);

    if (loading) {
        return (
            <div className="success-page">
                <h1>Finalisation de votre abonnement...</h1>
                <div className="loading-spinner"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="success-page error">
                <h1>Une erreur est survenue</h1>
                <p>{error}</p>
                <button onClick={() => navigate('/pricing')}>Retour aux abonnements</button>
            </div>
        );
    }

    return (
        <div className="success-page">
            <h1>Paiement réussi !</h1>
            <p>Votre abonnement a été activé avec succès.</p>
            <p>Vous allez être redirigé vers votre tableau de bord...</p>
        </div>
    );
};

export default SuccessPage;