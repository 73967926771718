import React, { useState } from 'react';
import { Box, Typography, Button, CircularProgress, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import VideocamIcon from '@mui/icons-material/Videocam';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import 'flag-icons/css/flag-icons.min.css';
import { Link } from 'react-router-dom';

const PRIMARY_COLOR = '#6366f1';
const SECONDARY_COLOR = '#4f46e5';

const Header = ({ projectName = "Untitled Project", language = "gb", onSave, onExport, isExporting, hasVideo }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        // Si on n'a pas de vidéo, on exporte directement l'audio
        if (!hasVideo) {
            onExport('audio');
            return;
        }
        // Sinon, on ouvre le menu
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Fonction améliorée pour gérer les clics sur les items du menu
    const handleMenuItemClick = (callback) => {
        // Fermer d'abord le menu
        setAnchorEl(null);
        // Attendre que le menu soit fermé avant d'exécuter le callback
        setTimeout(() => {
            callback();
        }, 100);
    };

    const exportAudio = () => {
        onExport('audio');
    };

    const exportVideo = () => {
        onExport('video');
    };

    // Style commun pour le bouton d'export
    const buttonStyle = {
        bgcolor: PRIMARY_COLOR,
        color: 'white',
        '&:hover': { bgcolor: SECONDARY_COLOR },
        mr: 1,
        textTransform: 'none',
        px: 2.8,
        py: .6,
        borderRadius: 2,
        fontWeight: 'bold',
        boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)'
    };

    return (
        <Box sx={{
            width: '100%',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            bgcolor: '#2A2A2A',
            borderBottom: '5px solid #232323',
            px: 2
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    height: '50px',
                    pr: 2,
                    mr: 2
                }}>
                    <Box component={Link} to="/dashboard" sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'none' }, display: 'block' }}>
                        <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold', fontSize: 30, mr: 1, lineHeight: '50px' }}>
                            Sumfy.
                        </Typography>
                    </Box>
                    <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.7)', lineHeight: '50px', fontSize: '0.75rem', position: 'relative', top: 'px' }}>
                        v1.0.0
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                }}>

                </Box>
            </Box>
            <Box>
                {hasVideo ? (
                    // Bouton avec menu déroulant si on a des options vidéo
                    <>
                        <Button
                            onClick={handleClick}
                            variant="contained"
                            endIcon={<KeyboardArrowDownIcon />}
                            startIcon={isExporting ? <CircularProgress size={20} color="inherit" /> : <DownloadIcon />}
                            disabled={isExporting}
                            aria-controls={open ? "export-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            sx={buttonStyle}
                        >
                            {isExporting ? 'Exporting...' : 'Export'}
                        </Button>
                        <Menu
                            id="export-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'export-button',
                                autoFocusItem: false
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            disableAutoFocusItem
                            disableAutoFocus
                            disableEnforceFocus
                            sx={{
                                '& .MuiPaper-root': {
                                    bgcolor: '#2A2A2A',
                                    color: 'white',
                                }
                            }}
                        >
                            <MenuItem onClick={() => handleMenuItemClick(exportAudio)}>
                                <ListItemIcon>
                                    <AudioFileIcon fontSize="small" sx={{ color: 'white' }} />
                                </ListItemIcon>
                                <ListItemText>Export Audio</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick(exportVideo)}>
                                <ListItemIcon>
                                    <VideocamIcon fontSize="small" sx={{ color: 'white' }} />
                                </ListItemIcon>
                                <ListItemText>Export Video</ListItemText>
                            </MenuItem>
                        </Menu>
                    </>
                ) : (
                    // Bouton simple si on n'a que l'option audio
                    <Button
                        onClick={handleClick}
                        variant="contained"
                        startIcon={isExporting ? <CircularProgress size={20} color="inherit" /> : <AudioFileIcon />}
                        disabled={isExporting}
                        sx={buttonStyle}
                    >
                        {isExporting ? 'Exporting...' : 'Export Audio'}
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default Header;