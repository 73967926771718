import React from 'react';
import './LegalNotice.css';

const LegalNotice = () => {
    return (
        <div className="legal-container">
            <div className="legal-content">
                <h1>Mentions Légales</h1>
                <p className="last-updated">Dernière mise à jour : 10 Mars 2025</p>

                <section className="legal-section">
                    <h2>1. Éditeur du site</h2>
                    <p>
                        Le site Sumfy est édité par Arthur Diard, auto-entrepreneur inscrit sous le numéro SIRET 93124277000010.
                        <br />
                        Adresse : Adresse communiquée sur demande pour des raisons de confidentialité, conformément à l’article L123-1-1 du Code de commerce.
                        <br />
                        📧 Email de contact : <a href="mailto:contact.sumfy@gmail.com">contact.sumfy@gmail.com</a>
                    </p>
                </section>

                <section className="legal-section">
                    <h2>2. Quels cookies utilisons-nous ?</h2>
                    <h3>Cookies essentiels (stockés en cache)</h3>
                    <p>
                        Ces cookies sont nécessaires au bon fonctionnement du site et ne peuvent pas être désactivés :
                    </p>
                    <ul>
                        <li>Token de connexion : permet de maintenir la session active.</li>
                        <li>User ID : identifiant unique pour gérer les sessions utilisateur.</li>
                        <li>Username : stocké pour faciliter l’identification.</li>
                        <li>Fingerprint : utilisé pour des raisons de sécurité et de prévention de la fraude.</li>
                    </ul>

                    <h3>Cookies analytiques (tiers)</h3>
                    <p>
                        Nous utilisons Google Analytics pour collecter des informations anonymes sur la navigation des utilisateurs
                        (pages visitées, durée de visite, etc.).
                    </p>
                </section>

                <section className="legal-section">
                    <h2>3. Hébergeur du site</h2>
                    <p>
                        Le site est hébergé par Hostinger.
                    </p>
                </section>

                <section className="legal-section">
                    <h2>4. Propriété intellectuelle</h2>
                    <p>
                        L’ensemble des contenus présents sur le site Sumfy (textes, images, vidéos, logos, code, etc.) est la propriété exclusive de Sumfy, sauf mention contraire. Toute reproduction ou diffusion sans autorisation préalable est interdite.
                    </p>
                </section>

                <section className="legal-section">
                    <h2>5. Données personnelles & RGPD</h2>
                    <p>
                        Nous collectons uniquement :
                    </p>
                    <ul>
                        <li>Adresses emails des utilisateurs (pour la création de compte).</li>
                        <li>Vidéos téléchargées pour permettre leur accès dans l’espace personnel.</li>
                    </ul>
                    <p>
                        ⏳ Durée de conservation : suppression des données après 24 mois d’inactivité.
                        <br />
                        📧 Exercice des droits (accès, rectification, suppression) : <a href="mailto:contact.sumfy@gmail.com">contact.sumfy@gmail.com</a>
                    </p>
                </section>

                <section className="legal-section">
                    <h2>6. Clause de non-association</h2>
                    <p>
                        Sumfy est une entreprise indépendante spécialisée dans le doublage et la traduction IA. Elle n’a aucun lien avec la société Somfy, spécialisée dans l’automatisation de l’habitat.
                    </p>
                </section>

                <section className="legal-section">
                    <h2>7. Responsabilité</h2>
                    <p>
                        L’éditeur du site ne peut être tenu responsable des erreurs de traduction automatique ni du contenu des vidéos téléchargées par les utilisateurs.
                    </p>
                </section>

                <section className="legal-section">
                    <h2>8. Droit applicable</h2>
                    <p>
                        Les présentes mentions légales sont soumises au droit français. En cas de litige, la juridiction compétente sera celle des tribunaux français.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default LegalNotice;