import React from 'react';
import { Box, Typography, Paper, Divider } from '@mui/material';
import AudioVolumeWidget from './AudioVolumeWidget';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const AudioVolumePanel = ({
    selectedBlock,
    transcript,
    onTranscriptUpdate,
    onSave,
    speakerOrder,
    audioPlayerRefs
}) => {
    if (!selectedBlock) {
        return (
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 2,
                    bgcolor: '#1E1E1E'
                }}
            >
                <Typography
                    variant="body2"
                    sx={{ color: 'rgba(255,255,255,0.7)', textAlign: 'center' }}
                >
                    Select an audio segment to adjust its volume
                </Typography>
            </Box>
        );
    }

    // Format the speaker name for display
    const formatSpeakerName = (speaker) => {
        if (speaker.startsWith('SPEAKER')) {
            const number = parseInt(speaker.split('_')[1]) + 1;
            return `SPEAKER ${number}`;
        }
        if (speaker.startsWith('BACKGROUND')) {
            const number = parseInt(speaker.split('_')[1]) + 1;
            return `BACKGROUND ${number}`;
        }
        return speaker;
    };

    // Format time in mm:ss.ms format
    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        const ms = Math.floor((seconds % 1) * 100);
        return `${mins}:${secs.toString().padStart(2, '0')}.${ms.toString().padStart(2, '0')}`;
    };

    return (
        <Box sx={{
            height: '100%',
            bgcolor: '#2D2D2D',
            p: 2,
            overflowY: 'auto',
            // Styles de scrollbar neutres (gris transparent)
            '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '8px',
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                }
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                borderRadius: '8px',
            },
        }}>
            <Paper
                elevation={0}
                sx={{
                    bgcolor: 'rgba(32, 33, 36, 0.9)',
                    p: 2,
                    borderRadius: '4px',
                    mb: 3,
                }}
            >
                <Box sx={{ mb: 2 }}>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: 'rgba(255,255,255,0.8)',
                            mb: 0.5,
                        }}
                    >
                        Selected Segment
                    </Typography>
                    <Divider sx={{ bgcolor: 'rgba(255,255,255,0.1)', mb: 1.5 }} />

                    <Box sx={{ display: 'grid', gridTemplateColumns: '100px 1fr', gap: 1, mb: 1 }}>
                        <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.6)' }}>Speaker:</Typography>
                        <Typography variant="body2" sx={{ color: 'white' }}>
                            {formatSpeakerName(selectedBlock.speaker)}
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'grid', gridTemplateColumns: '100px 1fr', gap: 1, mb: 1 }}>
                        <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.6)' }}>Time Range:</Typography>
                        <Typography variant="body2" sx={{ color: 'white' }}>
                            {formatTime(selectedBlock.start)} - {formatTime(selectedBlock.end)}
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'grid', gridTemplateColumns: '100px 1fr', gap: 1, mb: 1 }}>
                        <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.6)' }}>Duration:</Typography>
                        <Typography variant="body2" sx={{ color: 'white' }}>
                            {formatTime(selectedBlock.end - selectedBlock.start)}
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'grid', gridTemplateColumns: '100px 1fr', gap: 1, mb: 1 }}>
                        <Typography variant="caption" sx={{ color: 'rgba(255,255,255,0.6)' }}>Text:</Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: 'white',
                                wordBreak: 'break-word',
                                maxHeight: '80px',
                                overflowY: 'auto',
                                // Styles de scrollbar neutres pour le texte
                                '&::-webkit-scrollbar': {
                                    width: '4px',
                                    backgroundColor: 'transparent',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                    borderRadius: '4px',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                    }
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                        >
                            {selectedBlock.text || "No text available"}
                        </Typography>
                    </Box>
                </Box>

                <Typography
                    variant="subtitle2"
                    sx={{
                        color: 'rgba(255,255,255,0.8)',
                        mt: 2,
                        mb: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >

                    Volume Control
                </Typography>
                <Divider sx={{ bgcolor: 'rgba(255,255,255,0.1)', mb: 1.5 }} />

                <Box sx={{ p: 1, bgcolor: 'rgba(0,0,0,0)', borderRadius: '4px' }}>
                    <AudioVolumeWidget
                        selectedSegment={selectedBlock}
                        transcript={transcript}
                        onTranscriptUpdate={onTranscriptUpdate}
                        onSave={onSave}
                        audioPlayerRefs={audioPlayerRefs}
                    />
                </Box>

                <Typography
                    variant="caption"
                    sx={{
                        display: 'block',
                        color: 'rgba(255,255,255,0.5)',
                        mt: 1.8,
                        fontStyle: 'italic'
                    }}
                >
                    Adjust the volume of this audio segment. Changes are applied in real-time.
                </Typography>
            </Paper>
        </Box>
    );
};

export default AudioVolumePanel;