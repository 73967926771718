// src/pages/SettingsPage/SettingsPage.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../../services/auth.service';
import SubscriptionService from '../../services/subscription.service';
import Sidebar from '../../components/Dashboard/Sidebar';
import './SettingsPage.css';

const SettingsPage = () => {
    const navigate = useNavigate();
    const [notification, setNotification] = useState(null);
    const [loading, setLoading] = useState(true);
    // Ajout d'un état pour les informations d'abonnement
    const [subscription, setSubscription] = useState(null);
    const [cancelLoading, setCancelLoading] = useState(false);

    const [userData, setUserData] = useState({
        username: '',
        email: '',
        created_at: ''
    });

    const [passwordData, setPasswordData] = useState({
        currentPassword: '',
        newPassword: ''
    });

    useEffect(() => {
        loadUserData();
        loadSubscriptionData();
    }, []);

    const loadUserData = async () => {
        try {
            const data = await authService.getCurrentUser();
            setUserData(data);
        } catch (error) {
            showNotification('error', 'Erreur lors du chargement des données');
        } finally {
            setLoading(false);
        }
    };

    // Nouvelle fonction pour charger les données d'abonnement
    const loadSubscriptionData = async () => {
        try {
            const subscriptionData = await SubscriptionService.getCurrentSubscription();
            setSubscription(subscriptionData);
        } catch (error) {
            console.error('Erreur lors du chargement de l\'abonnement:', error);
            // Ne pas afficher de notification ici car ce n'est pas critique
        }
    };

    const showNotification = (type, message) => {
        setNotification({ type, message });
        setTimeout(() => setNotification(null), 3000);
    };

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        if (!passwordData.currentPassword || !passwordData.newPassword) {
            showNotification('error', 'Veuillez remplir tous les champs');
            return;
        }

        try {
            await authService.changePassword(passwordData);
            showNotification('success', 'Mot de passe modifié avec succès');
            setPasswordData({
                currentPassword: '',
                newPassword: ''
            });
        } catch (error) {
            showNotification('error', 'Erreur lors du changement de mot de passe');
        }
    };

    const handleLogout = () => {
        authService.logout();
        navigate('/login');
    };

    // Nouvelle fonction pour annuler l'abonnement
    const handleCancelSubscription = async () => {
        if (!subscription || subscription.status === 'none') {
            showNotification('error', 'Aucun abonnement actif à annuler');
            return;
        }

        try {
            setCancelLoading(true);
            await SubscriptionService.cancelSubscription();
            showNotification('success', 'Votre abonnement sera annulé à la fin de la période de facturation en cours');
            await loadSubscriptionData(); // Recharger les données d'abonnement
        } catch (error) {
            showNotification('error', 'Erreur lors de l\'annulation de l\'abonnement');
            console.error('Erreur annulation:', error);
        } finally {
            setCancelLoading(false);
        }
    };

    // Fonction pour afficher un nom lisible du plan
    const getPlanName = (planId) => {
        if (!planId) return 'Aucun plan';
        if (planId.startsWith('free')) return 'Plan Gratuit';
        if (planId.startsWith('classic')) return planId.includes('yearly') ? 'Classic Annuel' : 'Classic Mensuel';
        if (planId.startsWith('pro')) return planId.includes('yearly') ? 'Pro Annuel' : 'Pro Mensuel';
        return planId;
    };

    if (loading) {
        return (
            <div className="app-container">
                <Sidebar />
                <main className="main-content">
                    <div className="loading-spinner"></div>
                </main>
            </div>
        );
    }

    return (
        <div className="app-container">
            <Sidebar />
            <main className="main-content">
                <header className="content-header">
                    <h1 className="content-title">Paramètres</h1>
                </header>

                <div className="settings-container">

                    <section className="settings-section user-info-section">
                        <div className="section-content">
                            <div className="info-row">
                                <span className="info-label">Nom d'utilisateur</span>
                                <span className="info-value">{userData.username}</span>
                            </div>
                            <div className="info-row">
                                <span className="info-label">Email</span>
                                <span className="info-value">{userData.email}</span>
                            </div>
                            <div className="info-row">
                                <span className="info-label">Membre depuis</span>
                                <span className="info-value">
                                    {new Date(userData.created_at).toLocaleDateString('fr-FR', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}
                                </span>
                            </div>
                        </div>
                    </section>

                    {/* Nouvelle section pour l'abonnement */}
                    <section className="settings-section">
                        <h2>Abonnement</h2>
                        <div className="section-content">
                            {subscription ? (
                                <>
                                    <div className="info-row">
                                        <span className="info-label">Plan actuel</span>
                                        <span className="info-value">{getPlanName(subscription.planId)}</span>
                                    </div>
                                    <div className="info-row">
                                        <span className="info-label">Statut</span>
                                        <span className="info-value">
                                            {subscription.status === 'active'
                                                ? 'Actif'
                                                : subscription.status === 'active_until_end'
                                                    ? 'Annulation programmée'
                                                    : 'Inactif'}
                                        </span>
                                    </div>
                                    {subscription.monthlyCoins && (
                                        <div className="info-row">
                                            <span className="info-label">Crédits mensuels</span>
                                            <span className="info-value">{subscription.monthlyCoins}</span>
                                        </div>
                                    )}
                                    {subscription.endDate && (
                                        <div className="info-row">
                                            <span className="info-label">Date de fin</span>
                                            <span className="info-value">
                                                {new Date(subscription.endDate).toLocaleDateString('fr-FR', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric'
                                                })}
                                            </span>
                                        </div>
                                    )}
                                    {subscription.status === 'active_until_end' && (
                                        <div className="info-message">
                                            Votre abonnement sera annulé automatiquement à la fin de la période en cours.
                                        </div>
                                    )}
                                    <div className="subscription-actions">
                                        <button
                                            onClick={() => navigate('/pricing')}
                                            className="action-btn action-settings btn-primary"
                                        >
                                            <i className="fas fa-sync-alt"></i>
                                            Modifier mon abonnement
                                        </button>
                                        {subscription.status === 'active' && !subscription.planId?.startsWith('free') && (
                                            <button
                                                onClick={handleCancelSubscription}
                                                disabled={cancelLoading}
                                                className="action-btn action-settings btn-danger"
                                            >
                                                <i className="fas fa-ban"></i>
                                                {cancelLoading ? 'Annulation en cours...' : 'Annuler mon abonnement'}
                                            </button>
                                        )}
                                    </div>
                                    {subscription.status === 'active' && !subscription.planId?.startsWith('free') && (
                                        <p className="pricing-page__cancel-note">
                                            Votre abonnement restera actif jusqu'à la fin de la période de facturation en cours
                                        </p>
                                    )}
                                </>
                            ) : (
                                <div className="info-message">
                                    Chargement des informations d'abonnement...
                                </div>
                            )}
                        </div>
                    </section>

                    <section className="settings-section">
                        <h2>Changer le mot de passe</h2>
                        {notification && (
                            <div className={`notification-setting ${notification.type}`}>
                                <i className={`fas ${notification.type === 'success' ? 'fa-check-circle' : 'fa-exclamation-circle'}`}></i>
                                {notification.message}
                            </div>
                        )}
                        <form onSubmit={handlePasswordChange}>
                            <div className="password-fields">
                                <div className="form-group">
                                    <label htmlFor="currentPassword">Mot de passe actuel</label>
                                    <input
                                        type="password"
                                        id="currentPassword"
                                        value={passwordData.currentPassword}
                                        onChange={(e) => setPasswordData({ ...passwordData, currentPassword: e.target.value })}
                                        className="settings-input"
                                        placeholder="Entrez votre mot de passe actuel"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="newPassword">Nouveau mot de passe</label>
                                    <input
                                        type="password"
                                        id="newPassword"
                                        value={passwordData.newPassword}
                                        onChange={(e) => setPasswordData({ ...passwordData, newPassword: e.target.value })}
                                        className="settings-input"
                                        placeholder="Entrez votre nouveau mot de passe"
                                    />
                                </div>
                            </div>
                            <button type="submit" className="action-btn action-settings btn-primary">
                                <i className="fas fa-key"></i>
                                Mettre à jour le mot de passe
                            </button>
                        </form>
                    </section>

                    <section className="settings-section">
                        <h2>Session</h2>
                        <button onClick={handleLogout} className="action-btn action-settings btn-danger">
                            <i className="fas fa-sign-out-alt"></i>
                            Déconnexion
                        </button>
                    </section>
                </div>
            </main>
        </div>
    );
};

export default SettingsPage;