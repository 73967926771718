// src/services/fingerprintService.js
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { API_CONFIG } from '../config/constants';
import CryptoJS from 'crypto-js';

class FingerprintService {
    static async getFingerprint() {
        // Info hardware de base
        const hardwareInfo = {
            cores: navigator.hardwareConcurrency,
            memory: navigator.deviceMemory,
            screen: `${window.screen.width}x${window.screen.height}`
        };

        // Ajouter l'empreinte GPU via WebGL
        const canvas = document.createElement('canvas');
        const gl = canvas.getContext('webgl');
        if (gl) {
            const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
            hardwareInfo.gpuVendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
            hardwareInfo.gpuRenderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
        }

        return CryptoJS.SHA256(JSON.stringify(hardwareInfo)).toString();
    }

    static async getOrCreateFingerprint() {
        const stored = localStorage.getItem('device_fingerprint');
        if (stored) {
            return stored;
        }
        const fingerprint = await this.getFingerprint();
        localStorage.setItem('device_fingerprint', fingerprint);
        return fingerprint;
    }

    static async initializeUser() {
        try {
            const storedFingerprint = localStorage.getItem('device_fingerprint');
            let fingerprint;

            if (storedFingerprint) {
                fingerprint = storedFingerprint;
            } else {
                fingerprint = await this.getFingerprint();
                localStorage.setItem('device_fingerprint', fingerprint);
            }

            const deviceInfo = await this.getDeviceInfo();
            const ipAddress = await this.getIpAddress();

            const response = await fetch(`${API_CONFIG.BASE_URL}/initialize-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fingerprint,
                    ipAddress,
                    ...deviceInfo,
                    isReconnection: !!storedFingerprint
                }),
            });
            const data = await response.json();

            if (data.fingerprint && data.fingerprint !== fingerprint) {
                localStorage.setItem('device_fingerprint', data.fingerprint);
            }

            return data;
        } catch (error) {
            console.error('Error initializing user:', error);
            throw error;
        }
    }

    static async getDeviceInfo() {
        return {
            screenResolution: `${window.screen.width}x${window.screen.height}`,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            userAgent: navigator.userAgent,
        };
    }

    static async getIpAddress() {
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            return data.ip;
        } catch (error) {
            console.error('Error getting IP address:', error);
            return null;
        }
    }

    static async getUserCoins() {
        try {
            const token = localStorage.getItem('token');
            const fingerprint = await this.getFingerprint();

            const headers = {
                'Content-Type': 'application/json'
            };

            // Ajouter le token JWT s'il existe
            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }

            const response = await fetch(`${API_CONFIG.BASE_URL}/get-user-coins`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    fingerprint: fingerprint
                })
            });

            return await response.json();
        } catch (error) {
            console.error('Error getting user coins:', error);
            throw error;
        }
    }
}

export default FingerprintService;